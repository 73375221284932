import React from "react";
import JobpostCard from "./JobpostCard";

function Jobpost({ job_posts }) {
  return (
    <div>
      <section className="job-style-two pt-100 pb-70">
        <div className="container">
          <div className="section-title text-center">
            <h2>Jobs You May Be Interested In</h2>
            <p>Connecting The Right People To The Right Businesses!</p>
          </div>
          <div className="row">
            {job_posts &&
              job_posts.map((item) => {
                return <JobpostCard jobpost={item} />;
              })}
          </div>
        </div>
      </section>
    </div>
  );
}

export default Jobpost;
