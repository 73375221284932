import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom'

export const Header = (props) => {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])
  // console.log(window.location);


  const [toggle, setToggle] = useState(false)



  return (
    <div>
      <div className="navbar-area">
        {/* Menu For Mobile Device */}
        <div className="mobile-nav mean-container">
          <div className="mean-bar" onClick={() => { setToggle(!toggle) }}>
            <a
              href="#nav"
              className="meanmenu-reveal "
              style={{ background: '', color: '', right: 0, left: 'auto' }}
            >
              <span>
                <span>
                  <span />
                </span>
              </span>
            </a>
            <nav className="mean-nav" style={{ display: toggle ? 'block' : 'none' }}>
              <ul className="navbar-nav m-auto" >
                <li className="nav-item">
                  <Link className="nav-link active" to="/">
                    Home
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link dropdown-toggle" to="/about-us">
                    {' '}
                    About Us
                  </Link>
                  <ul className="dropdown-menu" style={{ display: 'none' }}>
                    <li className="nav-item">
                      <Link className="nav-link" to="/code-of-ethics">
                        {' '}
                        Code Of Ethics
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/our-unique-approach">
                        {' '}
                        Our Unique Approach
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/our-quality-policy">
                        {' '}
                        Our Quality Policy
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/our-people">
                        {' '}
                        Our People
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/corporate-overview">
                        {' '}
                        Corporate Overview
                      </Link>
                    </li>
                  </ul>
                  <a className="mean-expand" href="#" style={{ fontSize: 18 }}>
                    +
                  </a>
                </li>
                <li className="nav-item">
                  <Link className="nav-link dropdown-toggle" to="#">
                    Overview
                  </Link>
                  <ul className="dropdown-menu" style={{ display: 'none' }}>
                    <li className="nav-item">
                      <Link className="nav-link" to="/why-accord">
                        {' '}
                        Why Accord
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/work-with-us">
                        {' '}
                        Work With Us
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/our-clients">
                        {' '}
                        Our Clients
                      </Link>
                    </li>
                  </ul>
                  <a className="mean-expand" href="#" style={{ fontSize: 18 }}>
                    +
                  </a>
                </li>
                <li className="nav-item">
                  <Link className="nav-link dropdown-toggle" to="#">
                    Services
                  </Link>
                  <ul className="dropdown-menu" style={{ display: 'none' }}>
                    <li className="nav-item">
                      <Link className="nav-link" to="/compliance-management">
                        Compliance Management
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/rpo">
                        Global RPO
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/recruitment-process">
                        {' '}
                        Recruitment Process
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/executive-search">
                        Executive Search
                      </Link>
                    </li>
                  </ul>
                  <a className="mean-expand" href="#" style={{ fontSize: 18 }}>
                    +
                  </a>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/find-job">
                    {' '}
                    Current Openings
                  </Link>
                </li>
                <li className="nav-item mean-last">
                  <Link className="nav-link" to="/contact-us">
                    Contact Us
                  </Link>
                </li>
              </ul>
              {/* <div class="other-option">
        <a href="sign-in.html" class="signin-btn">Sign In</a>
      </div> */}
            </nav>
          </div>
          <Link className="logo" to="/">
            <img src="/assets/img/logo.png" alt="logo" />
          </Link>
        </div>
        {/* Menu For Desktop Device */}
        <div className="main-nav">
          <div className="container">
            <nav className="navbar navbar-expand-lg navbar-light">
              <Link className="navbar-brand" to="/">
                <img src="/assets/img/logo.png" alt="logo" />
              </Link>
              <div className="mean-push" />
              <div
                className="collapse navbar-collapse mean-menu"
                id="navbarSupportedContent"
                style={{ display: 'none' }}
              >
                <ul className="navbar-nav m-auto">
                  <li className="nav-item">
                    <Link className="nav-link active" to="/">
                      Home
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link dropdown-toggle" to="/about-us">
                      {' '}
                      About Us
                    </Link>
                    <ul className="dropdown-menu">
                      <li className="nav-item">
                        <Link className="nav-link" to="/code-of-ethics">
                          Code Of Ethics
                        </Link>
                      </li>
                      <li className="nav-item"></li>
                      <li className="nav-item">
                        <Link className="nav-link" to="/our-quality-policy">
                          {' '}
                          Our Quality Policy
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link" to="/our-people">
                          {' '}
                          Our People
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link" to="/corporate-overview">
                          {' '}
                          Corporate Overview
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link" to="/accord-life">
                          {' '}
                          Accord Life
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link dropdown-toggle" to="#">
                      {' '}
                      Overview
                    </Link>
                    <ul className="dropdown-menu">
                      <li className="nav-item">
                        <Link className="nav-link" to="/why-accord">
                          Why Accord
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link" to="/work-with-us">
                          {' '}
                          Work With Us
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link" to="/our-clients">
                          Our Clients
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link dropdown-toggle" to="#">
                      Services
                    </Link>

                    <ul className="dropdown-menu">
                      <li className="nav-item">
                        <Link className="nav-link" to="/compliance-management">
                          {' '}
                          Compliance Management
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link" to="/rpo">
                          Global RPO
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link" to="/recruitment-process">
                          {' '}
                          Recruitment Process
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link" to="/executive-search">
                          {' '}
                          Executive Search
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/find-job">
                      {' '}
                      Current Openings
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/contact-us">
                      Contact Us
                    </Link>
                  </li>
                </ul>
                {/* <div class="other-option">
        <a href="sign-in.html" class="signin-btn">Sign In</a>
      </div> */}
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
