import {
  GET_JOBPOSTS_STATED,
  GET_JOBPOSTS,
  GET_JOBPOSTS_ENDED,
  ADD_JOBPOST_STATED,
  ADD_JOBPOST,
  ADD_JOBPOST_ENDED,
  EDIT_JOBPOST_STATED,
  EDIT_JOBPOST,
  EDIT_JOBPOST_ENDED,
  GET_JOBPOST_STATED,
  GET_JOBPOST,
  GET_JOBPOST_ENDED,
  GET_ALL_JOBPOSTS_STATED,
  GET_ALL_JOBPOSTS,
  GET_ALL_JOBPOSTS_ENDED
} from "../types/jobpost_type";

const initialState = {
  jobposts_loading: true,
  jobposts: null,
  page: null,
  pages: null,
  total_jobposts: 0,

  jobpost: null,
  jobpost_loading: null,

  loading: true,

  jobpost_message: null,
  all_jobposts: null,
  all_jobposts_loading: null,
  add_jobpost_loading: true,
  edit_jobpost_loading: true
};

export const jobpost_reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_JOBPOSTS_STATED:
      return {
        ...state,
        jobposts: null,
        pages: null,
        page: null,
        total_jobposts: 0,
        jobposts_loading: true
      };
    case GET_JOBPOSTS:
      return {
        ...state,
        jobposts: payload.jobposts,
        pages: payload.pages,
        page: payload.page,
        total_jobposts: payload.count
      };
    case GET_JOBPOSTS_ENDED:
      return {
        ...state,
        jobposts_loading: false
      };
    case GET_ALL_JOBPOSTS_STATED:
      return {
        ...state,
        all_jobposts_loading: true,
        all_jobposts: null
      };
    case GET_ALL_JOBPOSTS:
      return {
        ...state,
        all_jobposts: payload
      };
    case GET_ALL_JOBPOSTS_ENDED:
      return {
        ...state,
        all_jobposts_loading: false
      };

    case ADD_JOBPOST_STATED:
      return {
        ...state,
        jobpost_message: null,
        add_jobpost_loading: true
      };
    case ADD_JOBPOST:
      return {
        ...state,
        jobpost_message: payload
      };
    case ADD_JOBPOST_ENDED:
      return {
        ...state,
        add_jobpost_loading: false
      };
    case GET_JOBPOST_STATED:
      return {
        ...state,
        jobpost: null,
        jobpost_loading: true
      };
    case GET_JOBPOST:
      return {
        ...state,
        jobpost: payload
      };
    case GET_JOBPOST_ENDED:
      return {
        ...state,
        jobpost_loading: false
      };
    case EDIT_JOBPOST_STATED:
      return {
        ...state,
        jobpost_message: null,
        edit_jobpost_loading: true
      };
    case EDIT_JOBPOST:
      return {
        ...state,
        jobpost_message: payload
      };
    case EDIT_JOBPOST_ENDED:
      return {
        ...state,
        edit_jobpost_loading: false
      };

    default:
      return state;
  }
};
