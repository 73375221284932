import React from 'react'
import { Link } from 'react-router-dom'


function JobInfo() {
  return (
    <div>
      <div className="job-info-two pt-100 pb-70">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="looking-job">
                <div className="media">
                  <i className="flaticon-group align-self-start mr-3" />
                  <div className="media-body">
                    <h5 className="mt-0">Looking For A Job?</h5>
                    <p>
                      Your next role could be with one of the top leading
                      organizations across the world.
                    </p>
                    <Link to="/find-job">
                      View Jobs
                      <i className="bx bx-chevrons-right" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="recruiting-card">
                <div className="media">
                  <i className="flaticon-resume align-self-start mr-3" />
                  <div className="media-body">
                    <h5 className="mt-0">Are You Recruiting?</h5>
                    <p>
                      We’re committed to making the recruitment process as
                      smooth as possible.
                    </p>
                    <Link to="/contact-us">
                      Contact Us
                      <i className="bx bx-chevrons-right" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default JobInfo
