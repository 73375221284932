import React from 'react'

function Why() {
  return (
    <div>
      <section className="why-choose-two pt-100pb-70 mt-50">
        <div className="container">
          <div className="section-title text-center">
            <h2>WHY CHOOSE ACCORD MANPOWER SERVICES?</h2>
            <p>
              Accord Manpower is the strong growing HR solutions company in
              India, with specialized service offerings in Executive Search,
              Recruitment Solutions, Staffing Services, Training and project
              based RPO hiring. Headquartered at Delhi, we provide world class
              HR services through our company owned offices across India to over
              200 organizations. The list of our satisfied customers includes
              leading MNCs and large Indian firms across industries.
            </p>
          </div>
          <div className="row">
            <div className="col-lg-4 col-sm-6">
              <div className="choose-card">
                <i className="flaticon-resume" />
                <h3>Code Of Ethics</h3>
                <p>
                  Accord Manpower is an active and responsible member of eminent
                  bodies who observe a strong code of ethics.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="choose-card">
                <i className="flaticon-recruitment" />
                <h3>Unique Approach</h3>
                <p>
                  At Accord Manpower, we focus on the company’s perennial needs
                  before initiating the process of recruitment.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
              <div className="choose-card">
                <i className="flaticon-employee" />
                <h3>Quality Policy</h3>
                <p>
                  We strive to provide our clients and candidates with services
                  which meet and exceed their expectations.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Why
