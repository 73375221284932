import React from "react";
import { Link } from "react-router-dom";

function Banner() {
  return (
    <div>
      <div className="banner-style-two">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="banner-text">
                <span>Find Jobs, Employment &amp; Career Opportunities</span>
                <h1>More Than Just A Job, We Offer An Opportunity To Grow!</h1>
                <p>
                  The key to our success is our team. At ACCORD the team members
                  are not treated as mere employees…they are our family members
                  and we ensure that each member of the family is able to
                  realize his personal and professional goals and aspirations.
                </p>
                <div className="theme-btn">
                  <Link to="/contact-us" className="default-btn">
                    Contact Us
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="banner-img">
          <img src="assets/img/banner/3.jpg" alt="banner image" />
        </div>
      </div>
    </div>
  );
}

export default Banner;
