import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

export const Breadcrum = ({ heading }) => {
  return (
    <div>
      <section className="breadcrumb-section type2 ">
        <div className="image-layer">
          <div className="container">
            <div className="main-breadcrumb">
              <div className="main-title-section">
                <h1>{heading}</h1>
              </div>

              <div className="breadcrumb">
                <div className="container">
                  <span className="simpletext">You are here:</span>
                  <Link to={'/'}>Home</Link>
                  <span className="fa fa-angle-double-right"></span>
                  <Link className="active" to={'#'}>
                    {heading}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Breadcrum)
