import React from 'react'

import { Link } from 'react-router-dom'

function Thankyou() {
  return (
    <div>
      <section className="ptb-50">
        <div className="container">
          <div className="row">
            <div className="col-md-6 ">
              <div className="thankyou-iamge">
                <img src="/assets/images/thankyou.png"></img>
              </div>
            </div>
            <div className="col-md-6">
              <div className="thankyou-content">
                <h3>Thank you!</h3>
                <h5>
                  Thank you for your inquiry regarding our services. Someone
                  from our team will be in touch with you shortly.
                </h5>
                <div class="contact-us mt-5 text-center">
                  <Link to="/" class="btn btn-contact-us">
                    Return Homepage
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Thankyou
