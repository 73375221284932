import React from 'react'
import { Link } from 'react-router-dom'
import Jobpost from './Jobpost'

function JobpostCard({ jobpost }) {
  return (
    <div>
      <div className="col-lg-12">
        <div className="job-card-two">
          <div className="row align-items-center">
            <div className="col-md-1">
              <div className="company-logo">
                <a href="job-details.html">
                  <img
                    style={{ height: '50px', width: 'auto' }}
                    src="assets/img/company-logo/job.png"
                    alt="logo"
                  />
                </a>
              </div>
            </div>
            <div className="col-md-8">
              <div className="job-info">
                <h3>
                  <Link to={`/job-post/${jobpost._id}`}>
                    {jobpost.posting_title} {jobpost.city}

                  </Link>
                </h3>
                <div className="jobpost-info">
                  <div className="jobpost-details">
                    <ul>
                      <li>
                        <p><strong>Industry:</strong>  {jobpost.industry}</p>
                      </li>

                      {
                      // <li>
                      //   <p><strong>Company:</strong>  {jobpost.client_name && jobpost.client_name.client_name}</p>
                      // </li>
                      }
                    

                      <li>
                        <p><strong>Job Description:</strong>  {jobpost.description}</p>
                      </li>
                    </ul>
                  </div>
                  <div className="jobpost-details">
                    <ul>
                      <li>
                        <p> <strong>Work Experience:</strong>  {jobpost.work_experience}</p>
                      </li>
                      <li>
                        <p><strong>Job Status:</strong>  {jobpost.job_opening_status}</p>
                      </li>
                      <li>
                        <p><strong>Job Type:</strong>  {jobpost.job_type && <span>{jobpost.job_type}</span>}</p>
                      </li>

                    </ul>
                  </div>
                </div>



              </div>
            </div>
            <div className="col-md-3">
              <div className="theme-btn text-end">
                <Link to={`/job-post/${jobpost._id}`} className="default-btn">
                  Apply Job
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default JobpostCard
