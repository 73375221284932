import React from 'react'
import { connect } from 'react-redux'
import Footer from "../common/footer/Footer";
import Header from "../common/header/Header";
import { Formik, Form } from "formik";
import { TextArea, TextInput } from "../Form/Form";
import * as Yup from "yup";
import PageBreadCrum from "../common/pageBreadcrum/PageBreadcrum";
import { useState } from "react";
import { addCandidate } from "../../store/actions/candidate_action"
import { convertToFormData, upload } from "../../shared/upload";
import { Link } from 'react-router-dom';


export const ThankyouJobpost = ({ addCandidate, loading }) => {


    return (
        <div>
            <Header />
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 thankyou-center">
                            <div className="thankyou-iamge">
                                <img style={{ width: "50%" }} src="/assets/img/thanyou-jobpost-1.jpg"></img>
                            </div>

                            <div className="thankyou-content">
                                <h3>Thank you!</h3>
                                <h5>
                                    Thank you for Applying Job.
                                </h5>
                                <div class="contact-us mt-3 mb-5 text-center">
                                    <button className='btn btn-primary'>
                                        <Link to="/find-job" class="btn btn-contact-us text-white">
                                            Return to Jobpost
                                        </Link>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ThankyouJobpost)