import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Testimonial() {
  var settings = {
    infinite: true,
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <div>
      <div className="testimonial-style-two ptb-100">
        <div className="container">
          <div className="section-title text-center">
            <h2>What Our Clients Say About Us?</h2>
            <p>
              Nobody can tell you about our services better than our clients.
              Don't take our word for it... Read what our clients feel about our
              services!
            </p>
          </div>
          <div className="row">
            <div className="col-md-12">

              <Slider {...settings} >
                <div className=" testimonial-box">
                  <div className="testimonial-item">
                    <i className="flaticon-left-quotes-sign" />
                    <p>
                      From last 1 Year we have been working together, I wanted to take this opportunity to appreciate the kind of support I am getting from Accord Manpower.
                    </p>
                    <p>
                      We are able to close multiple positions within the timelines and most of the CVs shared through your agency are relevant to the requirement.
                    </p>
                    <p>
                      Keep up the good work and service!
                    </p>
                    <div className="testimonial-info-text">
                      <h3>Ashish Saxena | Manager Human Resource | Global University Systems</h3>
                      {/* <p>Luhaif Digitech</p> */}
                    </div>
                  </div>
                </div>
                <div className=" testimonial-box">
                  <div className="testimonial-item">
                    <i className="flaticon-left-quotes-sign" />
                    <p>
                      We have worked with Accord Manpower for over a year now and they have recruited for and filled a large number of our roles. Aside from their success rate, we find their team, professional and helpful. They really listen to what our needs are and the type of candidates we want.
                    </p>
                    <p>
                      They are also very adaptable to our changing needs and never complain when the goal posts change or we are very selective in our hire. Great team, who work hard for their clients. Would always recommend.
                    </p>
                    <div className="testimonial-info-text">
                      <h3>Uma Bangari | HR HEAD | KRBL LTD.</h3>

                      {/* <p>Luhaif Digitech</p> */}
                    </div>
                  </div>
                </div>
                <div className=" testimonial-box">
                  <div className="testimonial-item">
                    <i className="flaticon-left-quotes-sign" />
                    <p>
                      Accord really went above and beyond to close the complicated positions in a timely manner. I can tell you are committed to the work and I really appreciate it.
                    </p>
                    <p> Consistency is one of your biggest strengths.</p>
                    <div className="testimonial-info-text">
                      <h3>Kyati | Manager Human Resource | Nilkamal Industries Ltd.</h3>

                      {/* <p>Luhaif Digitech</p> */}
                    </div>
                  </div>
                </div>
                <div className=" testimonial-box">
                  <div className="testimonial-item">
                    <i className="flaticon-left-quotes-sign" />
                    <p>
                      Accord has been very fruitful in my recruitment journey with Rio Tinto. Starting from the first call for explaining the job description to congratulatory call for getting through with the job - Accord has tracked the process well. They takes the candidature to logical conclusion which is very important for the candidate.
                    </p>
                    <div className="testimonial-info-text">
                      <h3>Prachi Madan | Talen Acquisition Lead | RIO TINTO</h3>

                      {/* <p>Luhaif Digitech</p> */}
                    </div>
                  </div>
                </div>
                <div className=" testimonial-box">
                  <div className="testimonial-item">
                    <i className="flaticon-left-quotes-sign" />
                    <p>
                      Team Accord helped us Hire the best talent for 9stacks. Team was quick and accommodating to our needs and budget. 9stacks is associated with Accord Manpower since 2017 and most of our team is hired through them. We are very thankful to them for helping us out. Our experience with them was great. Wishing them every bit of success
                    </p>
                    <div className="testimonial-info-text">
                      <h3>Ambika Kapoor | Human Resource | 9Stacks</h3>

                      {/* <p>Luhaif Digitech</p> */}
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Testimonial


