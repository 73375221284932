import React from 'react'
import Footer from '../../components/common/footer/Footer'
import Header from '../../components/common/header/Header'
import PageBreadCrum from '../../components/common/pageBreadcrum/PageBreadcrum'



function OurUniqueApproach({ pageHeading }) {
    return (
        <div>
            <Header />
            <PageBreadCrum pageHeading="Our Unique Approach" image={`/assets/img/ATS/our-unique-approach.png`}  />
            <div className="section">
                <div className="container">
                    <div className="row find-form">
                        <div className="col-md-12">
                            <div className="section-text">
                                <p>At Accord Manpower, we focus on the company’s perennial needs before initiating the process of recruitment. We don’t consider the hiring process as a job type. Rather we tread on much cautiously &amp; attentively. We deal it in critical terms. For the purpose, we are adept in locating the industry-specific search that includes a variety of industry’s processes, cycles and trends.</p>
                                <p>
                                    Apart from this, it is not only the study of company. Even the candidates who are to be recruited are identified with same diligence. We know that there is a difference between “speaking the language” of a particular industry and “executing with what you promised”. This leads us to identify the potential besides the adequate skill set of an aspiring candidate. Progress can never be achieved with faulty connections as it entirely depends on providing workable and actionable solutions keeping an eye on future. Our Unique Approach Includes:
                                </p>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <ul>
                                        <li>
                                            <p>Understanding client’s requirements, in-depth, which includes their organizational structure and environment?
                                            </p>
                                        </li>
                                        <li>
                                            <p>Taking “Ownership of the assignment” i.e. working on it wearing their shoes and representing the client’s company, honestly and professionally.
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                Open communication and transparency with both, clients and candidates. Having equal concern for their interest, ensuring long term association.
                                            </p>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-md-6">
                                    <ul>
                                        <li>
                                            <p>
                                                Detailed and carefully structured prescreening process which includes assessing required skill sets, strengths and weaknesses.
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                Short listing the candidates, who emulate the client company’s culture and possess the appropriate competencies and skills.
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                Using psychometric assessment tools, as and when required, explaining their importance in hiring, to the client.
                                            </p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}

export default OurUniqueApproach