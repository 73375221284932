import React from "react";
import { useEffect } from "react";
import Footer from "../../components/common/footer/Footer";
import Header from "../../components/common/header/Header";
import PageBreadCrum from "../../components/common/pageBreadcrum/PageBreadcrum";
import Jobpost from "../../components/Jobpost/Jobpost";
import { useSelectAllJobpost } from "../../shared/hooks/UseJobpost";

function FindJob({ pageHeading }) {
  const [jobpostData] = useSelectAllJobpost();
  const { all_jobposts, alll_jobposts_loading } = jobpostData;

  return (
    <div>
      <Header />
      <PageBreadCrum pageHeading="Find A Job" image={`/assets/img/ATS/current-opening.png`} />
      {all_jobposts && <Jobpost job_posts={all_jobposts} />}

      <section className="subscribe-section">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="section-title">
                <h2>Get New Job Notifications</h2>
                <p>Subscribe &amp; get all related jobs notification</p>
              </div>
            </div>
            <div className="col-md-6">
              <form
                className="newsletter-form"
                data-toggle="validator"
                noValidate="true"
              >
                <input
                  type="email"
                  className="form-control"
                  placeholder="Enter your email"
                  name="EMAIL"
                  required
                  autoComplete="off"
                />
                <button
                  className="default-btn sub-btn disabled"
                  type="submit"
                  style={{ pointerEvents: "all", cursor: "pointer" }}
                >
                  Subscribe
                </button>
                <div id="validator-newsletter" className="form-result" />
              </form>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default FindJob;
