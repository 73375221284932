export const GET_CANDIDATES_STATED = "GET_CANDIDATES_STATED";
export const GET_CANDIDATES = "GET_CANDIDATES";
export const GET_CANDIDATES_ENDED = "GET_CANDIDATES_ENDED";
export const ADD_CANDIDATE_STATED = "ADD_CANDIDATE_STARTED";
export const ADD_CANDIDATE = "ADD_CANDIDATE";
export const ADD_CANDIDATE_ENDED = "ADD_CANDIDATE_ENDED";
export const EDIT_CANDIDATE_STATED = "EDIT_CANDIDATE_STATED";
export const EDIT_CANDIDATE = "EDIT_CANDIDATE";
export const EDIT_CANDIDATE_ENDED = "EDIT_CANDIDATE_ENDED";
export const GET_CANDIDATE = "GET_CANDIDATE";
export const GET_CANDIDATE_STATED = "GET_CANDIDATE_STATED";
export const GET_CANDIDATE_ENDED = "GET_CANDIDATE_ENDED";
export const RESET_CANDIDATE = "RESET_CANDIDATE";
export const ERROR_CANDIDATE = "ERROR_CANDIDATE";
export const GET_ALL_CANDIDATES_STATED = "GET_ALL_CANDIDATES_STATED";
export const GET_ALL_CANDIDATES = "GET_ALL_CANDIDATES";
export const GET_ALL_CANDIDATES_ENDED = "GET_ALL_CANDIDATES_ENDED";
