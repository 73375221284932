import React from 'react'
import Footer from '../common/footer/Footer'
import Header from '../common/header/Header'
import PageBreadCrum from '../common/pageBreadcrum/PageBreadcrum'

function TermsAndConditions({pageHeading}) {
    return (
        <div>
            <Header />
            <PageBreadCrum pageHeading="Terms And Condition" image={`/assets/img/ATS/terms-&-conditions.png`} />
            <div className="section">
                <div className="container">
                    <div className="row find-form">
                        <div className="col-md-12">
                            <div className="section-text">
                                <h3>GENERAL DISCLAIMER</h3>
                                <p>Every effort has been made to ensure the accuracy of the information. The site is designed, updated and maintained by www.accordmanpower.com. The information contained in this Web site is intended, solely to provide general information for the personal use of the reader, who accepts full responsibility for its use and does not represent or endorse the accuracy or reliability of any information, including content or advertisements contained on, distributed through, or linked, downloaded or accessed from any of the services contained on this web site. The web site may contain inaccuracies, typographical and clerical errors, though efforts had been made to ensure accuracy. But www.accordmanpower.com. reserves the right to change, alter, and delete any of the representations, clauses in this website without prior notice or information.</p>

                                <h3>NO WARRANTIES</h3>
                                <p>
                                    www.accordmanpower.com. does not make any warranties, express or implied, including, without limitation, those of merchantability, non infringement and fitness for a particular purpose, and that the website will operate error free or that the website and its server are free of computer viruses or other harmful mechanisms with respect to any information, data, statements or products made available on the Site and is on a “as is” available basis only.
                                </p>

                                <h3>INDEMNITY</h3>
                                <p>
                                    Users agree to indemnify and hold www.accordmanpower.com. and its subsidiaries, affiliates, officers, agents, co-branders or other partners and employees, harmless from any claim or demand, including reasonable attorneys' fees made by any third party due to the content you submit, post or transmit through the Service, your use of the Service, your connection to the Service, your violation of the Terms and Conditions, or your violation of any rights of another user and any third party claims.
                                </p>

                                <h3>NO LIABILITY</h3>
                                <p>
                                    In no event shall www.accordmanpower.com. be liable for any direct, indirect, incidental, punitive, or consequential damages of any kind whatsoever with respect to the service, contents as found in this website. You further acknowledge and agree that www.accordmanpower.com., under no circumstances shall be liable for any direct, indirect, incidental, special, exemplary or consequential damages, including but not limited to, damages for loss of profits, goodwill, use, data or other intangible losses whatsoever.
                                </p>

                                <h3>INTELLECTUAL PROPERTY RIGHTS</h3>
                                <p>All Intellectual property rights are vested solely with www.accordmanpower.com. No part of the webpage/service shall be reproduced or transmitted or stored in any other web site, nor may any of its information or part thereof be disseminated in any electronic or non-electronic form, nor included in any public or private electronic retrieval system or service without obtaining prior written permission from www.accordmanpower.com. Any infringement in this regard shall be adequately compensated by the User .</p>

                                <h3>OTHER TERMS AND CONDITIONS</h3>
                                <p>By using this web site you are indicating your acceptance to abide by the Terms and Conditions which can be updated or modified at any time by www.accordmanpower.com..
                                    www.accordmanpower.com. do not disclose any personal information unless specifically requested by the user or required to do so by the law or in good faith that such disclosure is reasonably necessary to: (a) Comply with legal processes (b) Enforce the Terms and Conditions (c) Respond to claims that any content violates the rights of third parties or (d) Protect the rights, property or personal safety of www.accordmanpower.com., its users and the public.</p>

                                <p>www.accordmanpower.com. does not take any responsibility for the acts/omissions on the part of the user(s)/visitor(s)/customer(s) of the site. www.accordmanpower.com. shall not be obliged to resolve or mediate any dispute or difference, which may arise between users/visitors</p>

                                <p>Through this website you may be re - directed to other sites for various purposes and links. www.accordmanpower.com. shall not be liable for any of such sites and their related links.</p>

                                <p>
                                    This site and the intended activities are for the promotion and welfare of the society and does not contribute to any other unauthorized activities.

                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}

export default TermsAndConditions 