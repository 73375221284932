import React, { useState } from 'react'
import Banner from '../../components/banner/Banner'
import Category from '../../components/categories/Category'
import Footer from '../../components/common/footer/Footer'
import Header from '../../components/common/header/Header'
import JobInfo from '../../components/jobinfo/JobInfo'
import Testimonial from '../../components/testimonial/Testimonial'
import Why from '../../components/whyus/Why'
import Client from '../client/Client'
import Process from '../process/Process'

function Home() {
  return (
    <div>
      <Header />
      <Banner />
      <Why />
      <Category />
      <Process />
      <Client />
      <JobInfo />
      <Testimonial />
      <Footer />
    </div>
  )
}

export default Home
