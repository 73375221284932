import {
  GET_CANDIDATES_STATED,
  GET_CANDIDATES,
  GET_CANDIDATES_ENDED,
  ADD_CANDIDATE_STATED,
  ADD_CANDIDATE,
  ADD_CANDIDATE_ENDED,
  EDIT_CANDIDATE_STATED,
  EDIT_CANDIDATE,
  EDIT_CANDIDATE_ENDED,
  GET_CANDIDATE_STATED,
  GET_CANDIDATE,
  GET_CANDIDATE_ENDED,
  GET_ALL_CANDIDATES_STATED,
  GET_ALL_CANDIDATES,
  GET_ALL_CANDIDATES_ENDED
} from "../types/candidate_type";

const initialState = {
  candidates_loading: true,
  candidates: null,
  page: null,
  pages: null,
  total_candidates: 0,

  candidate: null,
  candidate_loading: null,

  loading: true,

  candidate_message: null,
  all_candidates: null,
  all_candidates_loading: null,
  add_candidate_loading: true,
  edit_candidate_loading: true
};

export const candidate_reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_CANDIDATES_STATED:
      return {
        ...state,
        candidates: null,
        pages: null,
        page: null,
        total_candidates: 0,
        candidates_loading: true
      };
    case GET_CANDIDATES:
      return {
        ...state,
        candidates: payload.candidates,
        pages: payload.pages,
        page: payload.page,
        total_candidates: payload.count
      };
    case GET_CANDIDATES_ENDED:
      return {
        ...state,
        candidates_loading: false
      };
    case GET_ALL_CANDIDATES_STATED:
      return {
        ...state,
        all_candidates_loading: true,
        all_candidates: null
      };
    case GET_ALL_CANDIDATES:
      return {
        ...state,
        all_candidates: payload
      };
    case GET_ALL_CANDIDATES_ENDED:
      return {
        ...state,
        all_candidates_loading: false
      };

    case ADD_CANDIDATE_STATED:
      return {
        ...state,
        candidate_message: null,
        add_candidate_loading: true
      };
    case ADD_CANDIDATE:
      return {
        ...state,
        candidate_message: payload
      };
    case ADD_CANDIDATE_ENDED:
      return {
        ...state,
        add_candidate_loading: false
      };
    case GET_CANDIDATE_STATED:
      return {
        ...state,
        candidate: null,
        candidate_loading: true
      };
    case GET_CANDIDATE:
      return {
        ...state,
        candidate: payload
      };
    case GET_CANDIDATE_ENDED:
      return {
        ...state,
        candidate_loading: false
      };
    case EDIT_CANDIDATE_STATED:
      return {
        ...state,
        candidate_message: null,
        edit_candidate_loading: true
      };
    case EDIT_CANDIDATE:
      return {
        ...state,
        candidate_message: payload
      };
    case EDIT_CANDIDATE_ENDED:
      return {
        ...state,
        edit_candidate_loading: false
      };

    default:
      return state;
  }
};
