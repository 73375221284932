import api from "../../domain/api";
import {
  GET_JOBPOSTS_STATED,
  GET_JOBPOSTS,
  GET_JOBPOSTS_ENDED,
  ADD_JOBPOST_STATED,
  ADD_JOBPOST,
  ADD_JOBPOST_ENDED,
  EDIT_JOBPOST_STATED,
  EDIT_JOBPOST,
  EDIT_JOBPOST_ENDED,
  GET_JOBPOST_STATED,
  GET_JOBPOST,
  GET_JOBPOST_ENDED,
  GET_ALL_JOBPOSTS_STATED,
  GET_ALL_JOBPOSTS,
  GET_ALL_JOBPOSTS_ENDED,
} from "../types/jobpost_type";
import * as qs from "qs";
import { handleError } from "../../shared/handleError";
import { setAlert } from "./alert";

export const addJobpost = (formData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADD_JOBPOST_STATED,
    });
    const { data } = await api.post(`/jobposts`, formData);
    dispatch({
      type: ADD_JOBPOST,
      payload: data,
    });
    dispatch({
      type: ADD_JOBPOST_ENDED,
    });
  } catch (error) {
    dispatch({
      type: ADD_JOBPOST_ENDED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getJobposts =
  ({ pageNumber = "" }) =>
    async (dispatch) => {
      try {
        dispatch({
          type: GET_JOBPOSTS_STATED,
        });
        const queryParams = qs.parse(window.location.search.replace("?", ""));
        const query = qs.stringify(queryParams, {
          encodeValuesOnly: true, // prettify url
        });

        const { data } = await api.get(
          `/jobposts?&pageNumber=${pageNumber}&post_on_career_page=YES&${query}`
        );

        dispatch({
          type: GET_JOBPOSTS,
          payload: data,
        });
        dispatch({
          type: GET_JOBPOSTS_ENDED,
        });
      } catch (error) {
        dispatch({
          type: GET_JOBPOSTS_ENDED,
        });
        dispatch(handleErrorLocal(error));
        dispatch(handleError(error));
      }
    };
export const getJobpost = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_JOBPOST_STATED,
    });
    const { data } = await api.get(`/jobposts/${id}`);

    dispatch({
      type: GET_JOBPOST,
      payload: data,
    });
    dispatch({
      type: GET_JOBPOST_ENDED,
    });
  } catch (error) {
    dispatch({
      type: GET_JOBPOST_STATED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const editJobpost = (id, formData) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_JOBPOST_STATED,
    });
    const { data } = await api.put(`/jobposts/${id}`, formData);
    dispatch({
      type: EDIT_JOBPOST,
      payload: data,
    });
    dispatch({
      type: EDIT_JOBPOST_ENDED,
    });
  } catch (error) {
    dispatch({
      type: EDIT_JOBPOST_ENDED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const deleteJobpost = (id) => async (dispatch) => {
  try {
    const { data } = await api.delete(`/jobposts/${id}`);
    dispatch(setAlert("Jobpost Deleted Successfully", "success"));
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getAllJobposts =
  ({ term, value }) =>
    async (dispatch) => {
      try {
        dispatch({
          type: GET_ALL_JOBPOSTS_STATED,
        });
        const { data } = await api.get(
          `/jobposts/all?term=post_on_career_page&value=YES&post_on_career_page=YES`
        );

        dispatch({
          type: GET_ALL_JOBPOSTS,
          payload: data,
        });
        dispatch({
          type: GET_ALL_JOBPOSTS_ENDED,
        });
      } catch (error) {
        dispatch({
          type: GET_ALL_JOBPOSTS_ENDED,
        });
        dispatch(handleErrorLocal(error));
        dispatch(handleError(error));
      }
    };

export const handleErrorLocal = () => async (dispatch) => { };
