import React from 'react'
import Footer from '../../components/common/footer/Footer'
import Header from '../../components/common/header/Header'
import PageBreadCrum from '../../components/common/pageBreadcrum/PageBreadcrum'


function ExecutiveSearch({pageHeading}) {
    return (
        <div>
            <Header />
            <PageBreadCrum pageHeading="Executive Search" image={`/assets/img/ATS/Services/executive-search.png`} />

            <div className="section">
                <div className="container">
                    <div className="row find-form">
                        <div className="col-md-12">
                            <div className="section-text">
                                <p>Leadership is the single most important contributing factor to an organization’s success. Board level appointments, Executive Search, CXO level solutions Senior Executives and Top Management form the most critical resources for any organization. Identifying, screening, assessing and recruiting qualified individuals to serve in a dynamic environment requires insight, creativity and experience, at Accord we are expert at this</p>
                                <p>
                                    Today companies are increasingly turning to specialized search firms to help them find leaders who can take them to newer heights. We have what it takes to help you hire the right person to occupy the C-Suite roles in your organization.
                                </p>
                                <p>
                                    A specialized wing of ACCORD, Search Partners is defined by the expertise, knowledge and network we share within sectors to play the crucial role of identifying, screening, assessing and hiring the best suitable resource. Our PAN India presence enables us to provide customized and personalized services to large corporate and growing organizations.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <Footer />
        </div>
    )
}

export default ExecutiveSearch