import React from 'react'
import Footer from '../../components/common/footer/Footer'
import Header from '../../components/common/header/Header'
import PageBreadCrum from '../../components/common/pageBreadcrum/PageBreadcrum'


function OurQualityPolicy({ pageHeading }) {
    return (
        <div>
            <Header />
            <PageBreadCrum pageHeading="Our Quality Policy" image={`/assets/img/ATS/About-us/our-quility-policy.png`}   />
            <div className="section">
                <div className="container">
                    <div className="row find-form">
                        <div className="col-md-12">
                            <div className="section-text">
                                <p>We strive to provide our clients and candidates with services which meet and exceed their expectations. We are committed to continuous improvement and have established a Quality Management System which provides a framework for measuring and improving our performance. The company is dedicated to the quality policy which will ensure that its services fully meet the requirements of its customers at all times. The goal of Accord Manpower is to achieve a high level of customer satisfaction. Commitment to the implementation of supporting managerial and operational systems is essential to realizing that goal.</p>
                                <p>
                                    Accord Manpower believes in the concept of client and supplier working together in pursuing this policy and in continually striving for improvements in service quality.
                                </p>
                            </div>
                            <div className="row">
                                <p>The quality policy is based on 3 fundamental principles:</p>
                                <div className="col-md-12">
                                    <ul>
                                        <li>
                                            <p>Ensuring that we fully identify and conform to the needs of our customers.
                                            </p>
                                        </li>
                                        <li>
                                            <p>Looking at our service provision processes, identifying the potential for errors and taking the necessary action to eliminate them.
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                Everyone understanding how to do their job and doing it right first time.
                                            </p>
                                        </li>
                                        <li>
                                            <p>To ensure that the policy is successfully implemented, staff will be responsible for identifying client’s requirements, and ensuring that the correct procedures are followed to meet those requirements.</p>
                                        </li>
                                    </ul>
                                </div>
                                <p>
                                    Objectives needed to ensure that the requirements of this policy are met and that continual improvement is maintained in line with the spirit of the policy, will be set, determined and monitored at Management Review.
                                </p>
                                <p>
                                    The quality policy principles and objectives will be communicated and available to staff at all times. Training will be an integral part of the strategy to achieve the objectives.
                                </p>
                                <p>
                                    Further to the above, the company undertakes to ensure that legislative and regulatory requirements relevant to scope of business will be regularly reviewed; as a minimum this will be at Management Reviews.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}

export default OurQualityPolicy