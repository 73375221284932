import React from 'react'
import Footer from '../../components/common/footer/Footer'
import Header from '../../components/common/header/Header'
import PageBreadCrum from '../../components/common/pageBreadcrum/PageBreadcrum'



function WorkWithUs({pageHeading}) {
    return (
        <div>
            <Header />
            <PageBreadCrum pageHeading="Work With Us"  image={`/assets/img/ATS/overview/work-with-us.png`}  />
           
            <div className="section">
                <div className="container">
                    <div className="row find-form">
                        <div className="col-md-12">
                            <div className="section-text">
                                <p>The key to our success is our team. At ACCORD the team members are not treated as mere employees…they are our family members and we ensure that each member of the family is able to realise his personal and professional goals and aspirations. A happy family member contributes more to the family and with those associated with the family, be it our clients, our associates or our internal customers. We offer more than careers… we give our people a happy work environment, training for learning and skills enhancement and opportunities for growth so that they can realise their full potential. Our people are our biggest assets and we preserve them and make them grow.</p>
                                <p>
                                    Once you join our family, you will discover that life at ACCORD is rich and purposeful not only in terms of your work experience, but also in terms of the people you interact with and the support you receive in living a complete life.
                                </p>
                                <p>
                                    To apply email your CV to careers@accordmanpower.com mentioning position in subject field.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}

export default WorkWithUs