import React from 'react'
import Footer from '../../components/common/footer/Footer'
import Header from '../../components/common/header/Header'
import PageBreadCrum from '../../components/common/pageBreadcrum/PageBreadcrum'

function OurClients({ pageHeading }) {
    return (
        <div>
            <Header />
            <PageBreadCrum pageHeading="Our Clients" image={`/assets/img/ATS/overview/our-clients.png`} />

            <div className="section">
                <div className="container">
                    <div className="row find-form">
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-3">
                                    <img className="client-logo" src="assets/img/clients-logo/logos-1.gif" alt />
                                </div><div className="col-md-3">
                                    <img className="client-logo" src="assets/img/clients-logo/logos-2.gif" alt />
                                </div><div className="col-md-3">
                                    <img className="client-logo" src="assets/img/clients-logo/logos-3.gif" alt />
                                </div><div className="col-md-3">
                                    <img className="client-logo" src="assets/img/clients-logo/logos-4.gif" alt />
                                </div><div className="col-md-3">
                                    <img className="client-logo" src="assets/img/clients-logo/logos-5.gif" alt />
                                </div><div className="col-md-3">
                                    <img className="client-logo" src="assets/img/clients-logo/logos-6.gif" alt />
                                </div><div className="col-md-3">
                                    <img className="client-logo" src="assets/img/clients-logo/logos-7.gif" alt />
                                </div><div className="col-md-3">
                                    <img className="client-logo" src="assets/img/clients-logo/logos-8.gif" alt />
                                </div><div className="col-md-3">
                                    <img className="client-logo" src="assets/img/clients-logo/logos-9.gif" alt />
                                </div><div className="col-md-3">
                                    <img className="client-logo" src="assets/img/clients-logo/logos-10.gif" alt />
                                </div><div className="col-md-3">
                                    <img className="client-logo" src="assets/img/clients-logo/logos-11.gif" alt />
                                </div><div className="col-md-3">
                                    <img className="client-logo" src="assets/img/clients-logo/logos-12.gif" alt />
                                </div><div className="col-md-3">
                                    <img className="client-logo" src="assets/img/clients-logo/logos-13.gif" alt />
                                </div><div className="col-md-3">
                                    <img className="client-logo" src="assets/img/clients-logo/logos-14.gif" alt />
                                </div><div className="col-md-3">
                                    <img className="client-logo" src="assets/img/clients-logo/logos-15.gif" alt />
                                </div><div className="col-md-3">
                                    <img className="client-logo" src="assets/img/clients-logo/logos-16.gif" alt />
                                </div><div className="col-md-3">
                                    <img className="client-logo" src="assets/img/clients-logo/logos-17.gif" alt />
                                </div><div className="col-md-3">
                                    <img className="client-logo" src="assets/img/clients-logo/logos-18.gif" alt />
                                </div><div className="col-md-3">
                                    <img className="client-logo" src="assets/img/clients-logo/logos-19.gif" alt />
                                </div><div className="col-md-3">
                                    <img className="client-logo" src="assets/img/clients-logo/logos-20.gif" alt />
                                </div><div className="col-md-3">
                                    <img className="client-logo" src="assets/img/clients-logo/logos-21.gif" alt />
                                </div><div className="col-md-3">
                                    <img className="client-logo" src="assets/img/clients-logo/logos-22.gif" alt />
                                </div><div className="col-md-3">
                                    <img className="client-logo" src="assets/img/clients-logo/logos-23.gif" alt />
                                </div><div className="col-md-3">
                                    <img className="client-logo" src="assets/img/clients-logo/logos-24.gif" alt />
                                </div><div className="col-md-3">
                                    <img className="client-logo" src="assets/img/clients-logo/logos-25.gif" alt />
                                </div><div className="col-md-3">
                                    <img className="client-logo" src="assets/img/clients-logo/logos-26.gif" alt />
                                </div><div className="col-md-3">
                                    <img className="client-logo" src="assets/img/clients-logo/logos-27.gif" alt />
                                </div><div className="col-md-3">
                                    <img className="client-logo" src="assets/img/clients-logo/logos-28.gif" alt />
                                </div><div className="col-md-3">
                                    <img className="client-logo" src="assets/img/clients-logo/logos-29.gif" alt />
                                </div><div className="col-md-3">
                                    <img className="client-logo" src="assets/img/clients-logo/logos-30.gif" alt />
                                </div><div className="col-md-3">
                                    <img className="client-logo" src="assets/img/clients-logo/logos-31.gif" alt />
                                </div><div className="col-md-3">
                                    <img className="client-logo" src="assets/img/clients-logo/logos-32.gif" alt />
                                </div><div className="col-md-3">
                                    <img className="client-logo" src="assets/img/clients-logo/logos-33.gif" alt />
                                </div><div className="col-md-3">
                                    <img className="client-logo" src="assets/img/clients-logo/logos-34.gif" alt />
                                </div><div className="col-md-3">
                                    <img className="client-logo" src="assets/img/clients-logo/logos-35.gif" alt />
                                </div><div className="col-md-3">
                                    <img className="client-logo" src="assets/img/clients-logo/logos-36.gif" alt />
                                </div><div className="col-md-3">
                                    <img className="client-logo" src="assets/img/clients-logo/logos-37.gif" alt />
                                </div><div className="col-md-3">
                                    <img className="client-logo" src="assets/img/clients-logo/logos-38.gif" alt />
                                </div><div className="col-md-3">
                                    <img className="client-logo" src="assets/img/clients-logo/logos-39.gif" alt />
                                </div><div className="col-md-3">
                                    <img className="client-logo" src="assets/img/clients-logo/logos-40.gif" alt />
                                </div><div className="col-md-3">
                                    <img className="client-logo" src="assets/img/clients-logo/logos-41.gif" alt />
                                </div><div className="col-md-3">
                                    <img className="client-logo" src="assets/img/clients-logo/logos-42.gif" alt />
                                </div><div className="col-md-3">
                                    <img className="client-logo" src="assets/img/clients-logo/logos-43.gif" alt />
                                </div><div className="col-md-3">
                                    <img className="client-logo" src="assets/img/clients-logo/logos-44.gif" alt />
                                </div><div className="col-md-3">
                                    <img className="client-logo" src="assets/img/clients-logo/logos-45.gif" alt />
                                </div><div className="col-md-3">
                                    <img className="client-logo" src="assets/img/clients-logo/logos-46.gif" alt />
                                </div><div className="col-md-3">
                                    <img className="client-logo" src="assets/img/clients-logo/logos-47.gif" alt />
                                </div><div className="col-md-3">
                                    <img className="client-logo" src="assets/img/clients-logo/logos-48.gif" alt />
                                </div><div className="col-md-3">
                                    <img className="client-logo" src="assets/img/clients-logo/logos-49.gif" alt />
                                </div><div className="col-md-3">
                                    <img className="client-logo" src="assets/img/clients-logo/logos-50.gif" alt />
                                </div><div className="col-md-3">
                                    <img className="client-logo" src="assets/img/clients-logo/logos-51.gif" alt />
                                </div><div className="col-md-3">
                                    <img className="client-logo" src="assets/img/clients-logo/logos-52.gif" alt />
                                </div><div className="col-md-3">
                                    <img className="client-logo" src="assets/img/clients-logo/logos-53.gif" alt />
                                </div><div className="col-md-3">
                                    <img className="client-logo" src="assets/img/clients-logo/logos-54.gif" alt />
                                </div><div className="col-md-3">
                                    <img className="client-logo" src="assets/img/clients-logo/logos-55.gif" alt />
                                </div><div className="col-md-3">
                                    <img className="client-logo" src="assets/img/clients-logo/logos-56.gif" alt />
                                </div><div className="col-md-3">
                                    <img className="client-logo" src="assets/img/clients-logo/logos-57.gif" alt />
                                </div><div className="col-md-3">
                                    <img className="client-logo" src="assets/img/clients-logo/logos-58.gif" alt />
                                </div><div className="col-md-3">
                                    <img className="client-logo" src="assets/img/clients-logo/logos-59.gif" alt />
                                </div><div className="col-md-3">
                                    <img className="client-logo" src="assets/img/clients-logo/logos-60.gif" alt />
                                </div><div className="col-md-3">
                                    <img className="client-logo" src="assets/img/clients-logo/logos-61.gif" alt />
                                </div><div className="col-md-3">
                                    <img className="client-logo" src="assets/img/clients-logo/logos-62.gif" alt />
                                </div><div className="col-md-3">
                                    <img className="client-logo" src="assets/img/clients-logo/logos-63.gif" alt />
                                </div><div className="col-md-3">
                                    <img className="client-logo" src="assets/img/clients-logo/logos-64.gif" alt />
                                </div><div className="col-md-3">
                                    <img className="client-logo" src="assets/img/clients-logo/logos-65.gif" alt />
                                </div><div className="col-md-3">
                                    <img className="client-logo" src="assets/img/clients-logo/logos-66.gif" alt />
                                </div><div className="col-md-3">
                                    <img className="client-logo" src="assets/img/clients-logo/logos-67.gif" alt />
                                </div><div className="col-md-3">
                                    <img className="client-logo" src="assets/img/clients-logo/logos-68.gif" alt />
                                </div><div className="col-md-3">
                                    <img className="client-logo" src="assets/img/clients-logo/logos-69.gif" alt />
                                </div><div className="col-md-3">
                                    <img className="client-logo" src="assets/img/clients-logo/logos-70.gif" alt />
                                </div><div className="col-md-3">
                                    <img className="client-logo" src="assets/img/clients-logo/logos-71.gif" alt />
                                </div><div className="col-md-3">
                                    <img className="client-logo" src="assets/img/clients-logo/logos-72.gif" alt />
                                </div><div className="col-md-3">
                                    <img className="client-logo" src="assets/img/clients-logo/logos-73.gif" alt />
                                </div><div className="col-md-3">
                                    <img className="client-logo" src="assets/img/clients-logo/logos-74.gif" alt />
                                </div><div className="col-md-3">
                                    <img className="client-logo" src="assets/img/clients-logo/logos-75.gif" alt />
                                </div><div className="col-md-3">
                                    <img className="client-logo" src="assets/img/clients-logo/logos-76.gif" alt />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}

export default OurClients