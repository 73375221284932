import React from 'react'
import Footer from '../common/footer/Footer'
import Header from '../common/header/Header'
import PageBreadCrum from '../common/pageBreadcrum/PageBreadcrum'

function PrivacyPolicy({ pageheading }) {
    return (
        <div>
            <Header />
            <PageBreadCrum pageHeading="Privacy Policy"  image={`/assets/img/ATS/privacy-policy.png`} />
            <div className="section">
                <div className="container">
                    <div className="row find-form">
                        <div className="col-md-12">
                            <div className="section-text">

                                <h3>Information Collection and Use</h3>
                                <p>We may collect the following personal information from you:</p>

                                <ul>
                                    <li>
                                        <p>Contact Information such as username, email address, mailing address, phone number</p>
                                    </li>
                                    <li>
                                        <p>Unique Identifiers such as user name, account number, password</p>
                                    </li>
                                    <li>
                                        <p>Information about your business such as company name, company size, business type, etc.</p>
                                    </li>
                                </ul>

                                <p>We use this information to do one or more of the following:</p>

                                <ul>
                                    <li>
                                        <p>Assess the needs of your business to determine suitable products</p>
                                    </li>
                                    <li>
                                        <p>Respond to customer service requests</p>
                                    </li>
                                    <li>
                                        <p>Send you a newsletter and marketing communications</p>
                                    </li>
                                    <li>
                                        <p>Respond to your questions and concerns</p>
                                    </li>
                                    <li>
                                        <p>Conduct research and analysis</p>
                                    </li>
                                    <li>
                                        <p>Improve our web site and marketing efforts</p>
                                    </li>
                                    <li>
                                        <p>Enable you to post your resume, search job postings, and contact or be contacted by prospective employerss</p>
                                    </li>
                                </ul>

                                <h3>Information Sharing</h3>

                                <p>Service Providers — We may provide your personal information to companies that provide services to help us with our business activities like offering customer service. These companies are authorized to use your personal information only as necessary to provide these services to us.</p>

                                <p>Legal Disclaimer — We may also disclose your personal information; (i) as required by law, such as to comply with a subpoena, or similar legal process; (ii) when we believe in good faith that disclosure is necessary to (a) protect our rights, (b) protect your safety or the safety of others, (c) investigate fraud or (d) respond to a government request; or (iii) to any other party with your consent.</p>

                                <h3>Information Related to Data Collected through our Unified Platform</h3>

                                <p>AccordManpower collects information under the direction of its clients and has no direct relationship with the individuals whose personal data it processes.
                                </p>

                                <p><strong>Choice</strong></p>

                                <p>We collect information for our clients. If you are an applicant or potential applicant for employment by one of our clients or have otherwise requested to receive information from one of our clients and would no longer like to be contacted by that client, please contact directly the client with which you interact via the address provided by the client, in its privacy policy or otherwise.</p>

                                <p><strong>Service Provider, Sub-Processors/Onward Transfer</strong></p>

                                <p>We may transfer personal information to companies that help us provide our service. Transfers to subsequent third parties are covered by the provisions in this Policy regarding notice and choice and the service agreements with our Clients.</p>

                                <p><strong>Access to Data Controlled by Our Clients</strong></p>

                                <p>We have no direct relationship with the individuals whose personal data we process on behalf of our clients. An individual who seeks access, or who seeks to correct, amend, or delete inaccurate data should direct his query to our client (the data controller). If the client requests us to remove the data, we will respond to their request within thirty (30) days.</p>

                                <p><strong>Data Retention</strong></p>

                                <p>We will retain personal data we process on behalf of our clients for as long as needed to provide services to our client. We will retain and use this personal information as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements.</p>

                                <h3>User Access and Choice</h3>

                                <p>We will retain your information for as long as your account is active or as needed to provide you services. We will retain and use your information as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements or as otherwise provided in the privacy policy of our client on whose behalf your information was provided.</p>

                                <p>Opt-out Preferences — If you wish to subscribe to our newsletter(s), we will use your name and email address to send the newsletter to you. Out of respect for your privacy, you may choose to stop receiving our newsletter or marketing emails by following the unsubscribe instructions included in these emails or accessing the email preferences in your account settings page.</p>

                                <h3>Tracking Technologies / Cookies</h3>

                                <p>Technologies such as: cookies, beacons, tags, scripts or similar technologies are used by AccordManpower and our marketing partners, affiliates, or analytics or service providers. These technologies are used in analyzing trends, administering the site, tracking users' movements around the site and to gather demographic information about our user base as a whole. We may receive reports based on the use of these technologies by these companies on an individual as well as aggregated basis.</p>

                                <p>We use cookies for site tracking, remembering feature preferences and related purposes. Users can control the use of cookies at the individual browser level. If you reject cookies, you may still use our site, but your ability to use some features or areas of our site may be limited.</p>

                                <h3>Security</h3>

                                <p>The security of your personal information submitted on the web and through our platform is important to us. When you enter sensitive information such as account passwords on forms, we encrypt the transmission of that information using secure socket layer technology (SSL).</p>

                                <h3>Additional Information</h3>

                                <p>Links to 3rd Party Sites — Our site includes links to other web sites whose privacy practices may differ from those of AccordManpower. If you submit personal information to any of those sites, your information is governed by their privacy policies. We encourage you to carefully read the privacy policy of any web site you visit.</p>

                                <p>Social Media Widgets — Our web site includes social media features, such as the Facebook Like button and widgets, such as the ShareThis button or interactive mini-programs that run on our site. Use of these features may allow us to collect your IP address, which page you are visiting on our site, and to set cookie to enable these features to function properly. Social media features and widgets are hosted either by a third party or directly on our web site. Your interaction with any of these features is governed by the privacy policy of the company providing it.</p>

                                <p>Any time you post on our blog please be aware that you are posting using a third party application and we have no access or control over this information. To request removal of your personal information from our blog, you can either log into the third party application and remove your comment or you can contact the appropriate third party application. Your interaction with these features is governed by the privacy policy of the company providing it.</p>

                                <p>Changes To This Policy — We may update this privacy policy to reflect changes to our information practices. If we make any material changes we will notify you by email or by means of a notice on this site. We encourage you periodically to review this page for the latest information on our privacy practices.</p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default PrivacyPolicy