import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Client() {
  var settings = {
    infinite: true,
    slidesToShow: 4,
    autoplay: true,
    autoplaySpeed: 4000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <div>
      <section className="company-section pt-50 pb-70">
        <div className="container">
          <div className="section-title text-center">
            <h2>SOME OF OUR VALUED CLIENTS</h2>
            <p>These opportunities are too good to miss!</p>
          </div>

          <section>
            <div className="container ">
              <Slider {...settings}>
                <div className="row">
                  <div className="col-md-3">
                    <img className="client-logo" src="assets/img/clients-logo/logos-1.gif" alt />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <img className="client-logo" src="assets/img/clients-logo/logos-2.gif" alt />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <img className="client-logo" src="assets/img/clients-logo/logos-3.gif" alt />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <img className="client-logo" src="assets/img/clients-logo/logos-4.gif" alt />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <img className="client-logo" src="assets/img/clients-logo/logos-5.gif" alt />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <img className="client-logo" src="assets/img/clients-logo/logos-6.gif" alt />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <img className="client-logo" src="assets/img/clients-logo/logos-7.gif" alt />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <img className="client-logo" src="assets/img/clients-logo/logos-8.gif" alt />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <img className="client-logo" src="assets/img/clients-logo/logos-9.gif" alt />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <img className="client-logo" src="assets/img/clients-logo/logos-10.gif" alt />
                  </div>
                </div>
              </Slider>
            </div>
          </section>

          <div className="text-center">
            <div className="theme-btn">
              <Link to="/our-clients" className="default-btn">
                {" "}
                View All{" "}
              </Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Client;
