import React from 'react'
import Footer from '../../components/common/footer/Footer'
import Header from '../../components/common/header/Header'
import PageBreadCrum from '../../components/common/pageBreadcrum/PageBreadcrum'


function CorporateOverview({pageHeading}) {
    return (
        <div>
            <Header />
            <PageBreadCrum pageHeading="Corporate Overview"  image={`/assets/img/ATS/About-us/corporate-overview.png`}  />
           
            <div className="section">
                <div className="container">
                    <div className="row find-form">
                        <div className="col-md-12">
                            <div className="section-text">
                                <p>Accord Manpower is the fastest growing HR solutions company in India, with specialized service offerings in Executive Search, Recruitment Solutions, Staffing Services, Training and project based RPO hiring. Headquartered at Delhi, we provide world class HR services through our company owned offices across India to over 200 organizations. The list of our satisfied customers includes leading MNCs and large Indian firms across industries.</p>
                                <p>
                                    Recruiting and retaining the right talent is critical to the stability and success of any organization. At Accord this is what we do the best.
                                </p>
                                <p>
                                    If your company is looking for leaders, managers or front line employees, at Accord we have the experience, expertise and depth of knowledge to find you the best talent. Our team of over 100 consultants comes with domain &amp; industry expertise and is focused on hiring the right people for your organization.
                                </p>
                                <p>
                                    Accord’s vision is to be a leading player in the Human Capital industry in the emerging markets across the world.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}

export default CorporateOverview