import React, { useEffect } from 'react'
// import 'slick-carousel/slick/slick.css'
// import 'slick-carousel/slick/slick-theme.css'

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { Provider } from 'react-redux'
import store from './store/store'
import BeforeLoginRoutes from './shared/Routes/BeforeLoginRoutes'
import PrivateRoutes from './shared/Routes/PrivateRoutes'
import PageNotFound from './containers/notfound/PageNotFound'
import Home from './containers/home/Home'
import Thankyou from './containers/thankyou/Thankyou'
import CodeOfEthics from './containers/about-us/CodeOfEthics'
import OurUniqueApproach from './containers/about-us/OurUniqueApproach'
import OurPeople from './containers/about-us/OurPeople'
import CorporateOverview from './containers/about-us/CorporateOverview'
import WhyAccord from './containers/overview/WhyAccord'
import WorkWithUs from './containers/overview/WorkWithUs'
import OurClients from './containers/overview/OurClients'
import ComplianceManagement from './containers/services/ComplianceManagement'
import Rpo from './containers/services/Rpo'
import RecruitmentProcess from './containers/services/RecruitmentProcess'
import ExecutiveSearch from './containers/services/ExecutiveSearch'
import FindJob from './containers/currentOpening/FindJob'
import ContactUs from './containers/contact-us/ContactUs'
import AboutUs from './containers/about-us/AboutUs'
import OurQualityPolicy from './containers/about-us/OurQualityPolicy'
import TermsAndConditions from './components/policy/TermsAndConditions'
import PrivacyPolicy from './components/policy/PrivacyPolicy'
import Apply from './components/Jobpost/Apply'
import { ThankyouJobpost } from './components/Jobpost/ThankyouJobpost'
import AccordLife from './components/accord-life/AccordLife'

function App() {
  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <Route exact path={'/'} component={Home} />

          <Route exact path={'/thankyou'} component={Thankyou} />

          <Route exact path={'/about-us'} component={AboutUs} />
          <Route exact path={'/code-of-ethics'} component={CodeOfEthics} />
          <Route
            exact
            path={'/our-unique-approach'}
            component={OurUniqueApproach}
          />
          <Route exact path={'/our-people'} component={OurPeople} />
          <Route
            exact
            path={'/our-quality-policy'}
            component={OurQualityPolicy}
          />
          <Route
            exact
            path={'/corporate-overview'}
            component={CorporateOverview}
          />
          <Route exact path={'/why-accord'} component={WhyAccord} />
          <Route exact path={'/job-post/:id'} component={Apply} />
          <Route exact path={'/work-with-us'} component={WorkWithUs} />
          <Route exact path={'/our-clients'} component={OurClients} />
          <Route
            exact
            path={'/compliance-management'}
            component={ComplianceManagement}
          />
          <Route exact path={'/rpo'} component={Rpo} />
          <Route exact path={'/rpo'} component={Rpo} />
          <Route
            exact
            path={'/recruitment-process'}
            component={RecruitmentProcess}
          />
          <Route exact path={'/executive-search'} component={ExecutiveSearch} />
          <Route exact path={'/find-job'} component={FindJob} />
          <Route exact path={'/contact-us'} component={ContactUs} />
          <Route exact path={'/thankyou-jobpost'} component={ThankyouJobpost} />
          <Route
            exact
            path={'/terms-and-conditions'}
            component={TermsAndConditions}
          />
          <Route exact path={'/privacy-policy'} component={PrivacyPolicy} />
          <Route exact path={'/accord-life'} component={AccordLife} />

          <Route component={PageNotFound} />
        </Switch>
      </Router>
    </Provider>
  )
}

export default App
