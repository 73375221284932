import React from 'react'
import Footer from '../../components/common/footer/Footer'
import Header from '../../components/common/header/Header'
import PageBreadCrum from '../../components/common/pageBreadcrum/PageBreadcrum'


function WhyAccord({pageHeading}) {
    return (
        <div>
            <Header />
            <PageBreadCrum pageHeading="Why Accord" image={`/assets/img/ATS/overview/why-accord.png`} />
            <div class="section">
                <div className="container">
                    <div className="row find-form">
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-6">
                                    <ul>
                                        <li>
                                            <h4>Sourcing</h4>
                                            <ul>
                                                <li>
                                                    <p>
                                                        Automated Database with more than 500,000 active candidates.
                                                    </p>
                                                </li>
                                                <li>
                                                    <p>
                                                        Access to multiple Job Portals.
                                                    </p>
                                                </li>
                                                <li>
                                                    <p>
                                                        Active Presence on social media sites like Linked In, Face book, Twitter.
                                                    </p>
                                                </li>
                                                <li>
                                                    <p>
                                                        Regular exercise to generate leads of passive candidates.
                                                    </p>
                                                </li>
                                            </ul>
                                            <h4>Validation</h4>
                                            <ul>
                                                <li>
                                                    <p>Resumes are validated against the Client’s mandatory requirements (i.e./all mandatory requirements highlighting) through a client specific set of questioner.</p>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-md-6">
                                    <ul>
                                        <li>
                                            <h4>Candidate Management</h4>
                                            <ul>
                                                <li>
                                                    <p>Birthday Wished through Emails</p>
                                                </li>
                                                <li>
                                                    <p>Coffee Meetings with prospect candidates</p>
                                                </li>
                                                <li>
                                                    <p>Meetings with Sr. Level Candidates</p>
                                                </li>
                                            </ul>
                                            <h4>Delivery</h4>
                                            <ul>
                                                <li>
                                                    <p>TAT (Turn Around Time) is always on top of every recruiter mind and delivery is always on the committed deadlines.</p>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <Footer />
        </div>
    )
}

export default WhyAccord