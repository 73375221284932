import React from 'react'
import Footer from '../../components/common/footer/Footer'
import Header from '../../components/common/header/Header'
import PageBreadCrum from '../../components/common/pageBreadcrum/PageBreadcrum'


function RecruitmentProcess({pageHeading}) {
    return (
        <div>
            <Header />
            <PageBreadCrum pageHeading="Recruitment Process" image={`/assets/img/ATS/Services/Requirement-process.png`} />

            <div className="section">
                <div className="container">
                    <div className="row find-form">
                        <div className="col-md-12">
                            <p>At Accord Manpower, we not only provide recruitment consultancy services to our clients but also give the applicants a perfect platform to get their dream jobs. We can help our clients to get the suitable candidates, cultivate them and retain them. Our comprehensive recruitment and staffing solutions as HR consultant ensure the delivery of profitable propositions for your workforce requirements.</p>
                            <p>We have adopted the international practices and procedures that are designed to attract and hire the best talent available in the market; thereby offering the best employee recruitment. We provide our client companies with not only a set of candidates, but also discussion on best-fit market availability, comparative benchmarking and a comfort knowing. Our online recruitment solutions are the best in the market today.</p>
                            <p><strong>Our Recruitment Process can be categorized into three phases, namely: –</strong></p>
                            <ul>
                                <li>
                                    <p>Planning – Structure, Focus, Identity, Prepare</p>
                                </li>
                                <li>
                                    <p>Implementation – Contact, Motivate, Evaluate</p>
                                </li>
                                <li>
                                    <p>Closure – Select, Offer, Candidate joining</p>
                                </li>
                            </ul>
                            <p><strong>At Accord Manpower, we follow a carefully structured recruitment and selection process, which starts right from understanding the clients’ needs and functions all the way to follow-ups with both clients and candidates post recruitment. A brief description of the different stages involved in our recruitment process is given below: –</strong></p>
                            <div className="row">
                                <div className="col-md-6">
                                    <h4>Planning</h4>
                                    <p>In the planning stage, we evaluate the needs and requirements of our clients to determine the required set of skills, understand organisational behaviour and relationships. This forms an integral part of our customer-focused recruitment services. Working as a cohesive team with our client, we identify the required experience and other characteristics, which are necessary for the successful candidate.</p>
                                    <p>We select those candidates, who fit in well with the company’s plan. In order to ensure the best recruitment online of a suitable candidate, we give prime consideration to the company’s existing business activities, business plans, work-culture, structures, systems and future plans. We concentrate on the job description, its key result areas, opportunities, career implications, location, salary and benefits.</p>
                                    <p>The initial planning enable us in identifying the needs of both the company as well as the candidates, which further leads us to deciding on a search strategy to target on specific skills, education, personality and experience. It is because of this comprehensive and strategic planning, we are today one of the most sought after recruitment agencies in the market.</p>
                                    <h4>Mapping Exercise</h4>
                                    <p>Being an efficient recruitment agency, during the mapping exercise, we perform industry specific desk and field research on companies and relevant people so as to identify the key competencies. It includes job title, principal accountabilities, key result areas (KRAs), authority to be infested, external/internal contacts, reporting procedures and supervisory relationships, working conditions / environment, job specifications, compensation package, location and other necessary details. Amongst several recruitment online companies, Accord Manpower is your most trusted staffing provider.</p>
                                    <h4>Long List Generation</h4>
                                    <p>The Field research is one of the primary aspects of effective recruitment online services. It provides us a long list of qualified candidates, who are worth pursuing. After this process, we start the search to identify the suitable candidates either from our own exhaustive database or by advertising for the position.</p>
                                    <h4>Implementation</h4>
                                    <p>The implementation stage consists of several phases including, preliminary interviews, short-listing of candidates, informal reference checks, client interviews and formal reference checks.</p>
                                    <h4>Preliminary Interviews</h4>
                                    <p>Being an efficient recruitment agency, during the mapping exercise, we perform industry specific desk and field research on companies and relevant people so as to identify the key competencies. It includes job title, principal accountabilities, key result areas (KRAs), authority to be infested, external/internal contacts, reporting procedures and supervisory relationships, working conditions / environment, job specifications, compensation package, location and other necessary details. Amongst several recruitment online companies, Accord Manpower is your most trusted staffing provider.</p>
                                    <h4>Implementation</h4>
                                    <p>The implementation stage consists of several phases including, preliminary interviews, short-listing of candidates, informal reference checks, client interviews and formal reference checks.</p>
                                </div>
                                <div className="col-md-6">
                                    <h4>Informal reference Checks</h4>
                                    <p>We conduct informal reference check to verify the listed achievements and last few work experiences of the short-listed candidates. After this phase, a list is handed to the client with our recommendations and assessments.</p>
                                    <h4>Client Interviews</h4>
                                    <p>After the informal reference checks, we schedule a meeting between the short-listed candidates and the client with proper travel arrangements and handling of reimbursement of travel expenses. The consultant asks both the client and the candidates for the feedback after every interview.</p>
                                    <h4>Formal Reference Checks</h4>
                                    <p>After client interviews, we conduct a formal reference check of the selected candidates. Our recruitment consultants contacts individuals / companies that are capable of providing details of the candidate’s qualifications, work ethics and other relevant feedback. The comments made by these references are then reviewed with the client.</p>
                                    <h4>Closure</h4>
                                    <p>The closure stage consists of negotiation &amp; offer, Follow-up / progress and handholding.</p>
                                    <h4>Negotiation &amp; Offer</h4>
                                    <p>We actively participate in the negotiation process including compensation. Both the client and the candidates draw on our in-depth knowledge of market compensation and benefits in order to discuss and plan a competitive and equitable package.</p>
                                    <h4>Follow Up / Progress</h4>
                                    <p>Understanding the importance of follow up / progress phase, we keep in touch with the candidate until the time he / she joins the company.</p>
                                    <h4>Handholding</h4>
                                    <p>After the successful candidate joining for a specified job, we keep an ongoing dialogue with both the candidate and the client. This handholding process ensures a smooth integration into the organisation and accomplishment of targets and performance goals.</p>
                                    <h4>Short List Generation</h4>
                                    <p>The preliminary interviews result out in short-listing of highly qualified candidates. Confidential reports are generally prepared and submitted in writing to our clients on four or five individuals, who fulfill the specified position closely.</p>
                                    <h4>Long List Generation</h4>
                                    <p>The Field research is one of the primary aspects of effective recruitment online services. It provides us a long list of qualified candidates, who are worth pursuing. After this process, we start the search to identify the suitable candidates either from our own exhaustive database or by advertising for the position.</p>
                                    <h4>Preliminary Interviews</h4>
                                    <p>For preliminary interviews, we select the potential candidates on the basis of prior work experience, qualifications, strength and weaknesses and the ability of candidate to fit into the work-culture and ethics of the client organization. The potential candidates are contacted and interviews will be conducted to find out their interest and suitability. We deploy the necessary expert assessment after the screening of candidates by drawing up competency profiles through the most innovative proprietary platform. During this phase, our consultants keep on updating the client of the progress achieved.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <Footer />
        </div>
    )
}

export default RecruitmentProcess