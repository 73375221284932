import React from 'react'
import { connect } from 'react-redux'
import Footer from "../common/footer/Footer";
import Header from "../common/header/Header";
import { Formik, Form } from "formik";
import { TextArea, TextInput } from "../../components/Form/Form";
import * as Yup from "yup";
import PageBreadCrum from "../common/pageBreadcrum/PageBreadcrum";
import { useState } from "react";
import { addCandidate } from "../../store/actions/candidate_action"
import { convertToFormData, upload } from "../../shared/upload";
import ThankyouJobpost from './ThankyouJobpost';
import { useSelectAllJobpost, useSingleJobpost } from "../../shared/hooks/UseJobpost";
import Spinner from "../../components/layout/Spinner"
import { Link } from "react-router-dom";
import renderHTML from "react-render-html";
import Helmet from "react-helmet";
import * as qs from "qs";






export const Apply = ({ addCandidate, loading, match }) => {
  const [data] = useSingleJobpost(match.params.id);
  const queryParams = qs.parse(window.location.search.replace("?", ""));
  const { jobpost } = data;

  const [featuredImage, setFeaturedImage] = useState(null);

  const [isLoading, setIsLoading] = useState(false)
  // const submitFormClicked = async (values) => {
  //   values.position_name = match.params.id
  //   const Formdata = await convertToFormData({ values, featuredImage });
  //   await addCandidate(Formdata);
  // };

  return (
    <div>
      {jobpost && (
        <Helmet>
          <title>{jobpost.posting_title}</title>
          <meta property="og:title" key="og:title" content={jobpost.posting_title} />
          <meta
            property="og:description"
            key="og:description"
            content={jobpost.job_description}
          />
        </Helmet>
      )}

      <Header />
      <PageBreadCrum
        pageHeading="Apply"
        image={`/assets/img/ATS/current-opening.png`}
      />
      <section className="contact-form-section pb-100">
        <div className="container">
          <div className="single-job-details mt-30">
            <div className="row">
              <div className="col-md-7">
                <div className="jobpost-box"></div>
                {
                  !loading ? (
                    jobpost && (
                      <div className="job-info-apply">
                        <div className="job-info-title text-center">
                          <h2>Job info <span className="c-red" >Details</span></h2>
                        </div>
                        <hr />
                        <h3>
                          {jobpost.posting_title} {jobpost.city}
                        </h3>
                        <h4> {jobpost.job_opening_status}</h4>

                        <div className="jobpost-info">
                          <div className="jobpost-details">
                            <ul>
                              <li>
                                <p><strong>Industry:</strong>  {jobpost.industry}</p>
                              </li>
                              {
                              // <li>
                              //   <p><strong>Company:</strong>  {jobpost.client_name && jobpost.client_name.client_name}</p>
                              // </li>
                              }
                              <li>
                                <p><strong>Job Type:</strong>  {jobpost.job_type && <span>{jobpost.job_type}</span>}</p>
                              </li>
                            </ul>
                          </div>
                          <div className="jobpost-details">
                            <ul>
                              <li>
                                <p> <strong>Work Experience:</strong>  {jobpost.work_experience}</p>
                              </li>
                              <li>
                                <p><strong>Job Status:</strong>  {jobpost.job_opening_status}</p>
                              </li>
                            </ul>
                          </div>

                        </div>
                        <div>
                          <p><strong>Job Description:</strong> {jobpost.job_description &&
                            renderHTML(jobpost.job_description)} </p>

                        </div>
                      </div>
                    )
                  ) : (
                    <div>
                      <Spinner />
                    </div>
                  )
                }
              </div>
              <div className="col-md-5">
                <div className="contact-area">
                  <Formik
                    initialValues={{
                      name: "",
                      phone_number: "",
                      email_id: "",
                      curr_company_name: "",
                      curr_company_designation: "",
                      total_experience: "",
                      annual_salary: "",
                      expected_ctc: "",
                      notice_period: "",
                      current_location: "",
                    }}
                    validationSchema={Yup.object({
                      name: Yup.string().required("Required"),
                      phone_number: Yup.string().required("Required"),
                      email_id: Yup.string().required("Required"),
                      curr_company_name: Yup.string().required("Required"),
                      curr_company_designation: Yup.string().required("Required"),
                      total_experience: Yup.string().required("Required"),
                      annual_salary: Yup.string().required("Required"),
                      expected_ctc: Yup.string().required("Required"),
                      notice_period: Yup.string().required("Required"),
                      current_location: Yup.string().required("Required"),
                    })}
                    onSubmit={async (
                      values,
                      { setSubmitting, resetForm }
                    ) => {
                      setSubmitting(true);
                      values.position_name = match.params.id
                      values.source = queryParams.source ? queryParams.source : "Website"
                      console.log("Value mai kya hai -", values);
                      const Formdata = convertToFormData({ values, featuredImage });
                      console.log("Form Data -", Formdata);

                      console.log("SOURCE", values.source);
                      await addCandidate(Formdata);
                      console.log("candidate save ho gaya");
                      resetForm();
                      setSubmitting(false);
                      window.location.href = '/thankyou-jobpost'
                    }}
                  >
                    {(formik) => {
                      console.log(formik);

                      return (
                        <Form>
                          <div className="row">
                            <div className="form-title">
                              <h3>Apply <span className="c-red">Now </span></h3>
                            </div>
                            <hr />
                            <div className="col-md-6">
                              <TextInput
                                label="Candidate Name"
                                name="name"
                                type="text"
                              // placeholder="Candidate Name"
                              />
                            </div>
                            <div className="col-md-6">
                              <TextInput
                                label="Candidate Number"
                                name="phone_number"
                                type="number"
                              // placeholder="Candidate Number"
                              />
                            </div>
                            <div className="col-md-6">
                              <TextInput
                                label="Email Address"
                                name="email_id"
                                type="text"
                              // placeholder="Email Address"
                              />
                            </div>
                            <div className="col-md-6">
                              <TextInput
                                label="Current Company"
                                name="curr_company_name"
                                type="text"
                              // placeholder="Current Company"
                              />
                            </div>
                            <div className="col-md-6">
                              <TextInput
                                label="Designation"
                                name="curr_company_designation"
                                type="text"
                              // placeholder="Designation"
                              />
                            </div>
                            <div className="col-md-6">
                              <TextInput
                                label="Work Experience"
                                name="total_experience"
                                type="number"
                              // placeholder="Work Experience"
                              />
                            </div>
                            <div className="col-md-6">
                              <TextInput
                                label="Current Salary"
                                name="annual_salary"
                                type="number"
                              // placeholder="Current Salary"
                              />
                            </div>
                            <div className="col-md-6">
                              <TextInput
                                label="Expected Salary"
                                name="expected_ctc"
                                type="number"
                              // placeholder="Expected Salary"
                              />
                            </div>
                            <div className="col-md-6">
                              <TextInput
                                label="Notice Period"
                                name="notice_period"
                                type="text"
                              // placeholder="Notice Period"
                              />
                            </div>
                            <div className="col-md-6">
                              <TextInput
                                label="Current Location"
                                name="current_location"
                                type="text"
                              // placeholder="Current Location"
                              />
                            </div>
                            <input
                              name='cv_attachment'
                              type="file"
                              onChange={(e) => { setFeaturedImage({ cv_attachment: e.target.files[0] }) }}
                            />

                          </div>
                          <div className="row">
                            <div className="col-lg-12 col-md-12 text-center mt-3">
                              <button
                                type="submit"
                                className="default-btn contact-btn disabled"
                                style={{ pointerEvents: "all", cursor: "pointer" }}
                              >
                                Submit
                              </button>
                              <div
                                id="msgSubmit"
                                className="h3 alert-text text-center hidden"
                              />
                              <div className="clearfix" />
                            </div>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section >
      <Footer />
    </div >
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = { addCandidate }

export default connect(mapStateToProps, mapDispatchToProps)(Apply)