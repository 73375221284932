import React from 'react'
import { Link } from "react-router-dom"

function Process() {
  return (
    <div>
      <section className="use-section pt-100 pb-70">
        <div className="container">
          <div className="section-title text-center">
            <h2>Easiest Way To Use</h2>
          </div>
          <div className="row">
            <div className="col-md-4 col-sm-6">
              <div className="use-text">
                <span>1</span>
                <Link to={`/find-job`}>
                  <i className="flaticon-website" />
                  <h3>Browse Job</h3>
                  <p>You need the best, we have the best.</p>
                </Link>
              </div>
            </div>
            <div className="col-md-4 col-sm-6">
              <div className="use-text">
                <span>2</span>
                <i className="flaticon-recruitment" />
                <h3>Find Right Vacancies</h3>
                <p>We provide plenty of opportunities for everyone.</p>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 offset-sm-3 offset-md-0">
              <div className="use-text">
                <span>3</span>
                <i className="flaticon-login" />
                <h3>Submit Your Resume</h3>
                <p>What we do is match the right person to the right job.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Process
