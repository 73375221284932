import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

const PageNotFound = () => {
  return (
    <div>
      <section className="">
        <div className="container">
          <div className="row">
            <div className="col-md-12 ">
              <div className="nofound-iamge">
                <img src="/assets/images/404.png"></img>
              </div>
            </div>
            <div className="col-md-12">
              <div className="thankyou-content text-center">
                <div class="contact-us  text-center">
                  <Link to="/" class="btn btn-contact-us">
                    Return Homepage
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(PageNotFound)
