import React from 'react'
import Breadcrum from '../../components/common/breadcrum/Breadcrum'
import Footer from '../../components/common/footer/Footer'
import Header from '../../components/common/header/Header'
import PageBreadCrum from '../../components/common/pageBreadcrum/PageBreadcrum'


function CodeOfEthics({ pageHeading }) {
    return (
        <div>
            <Header />
            <PageBreadCrum pageHeading="Code Of Ethics" image={`/assets/img/ATS/About-us/code-of-ethics.png`}  />
            <div className="section">
                <div className="container">
                    <div className="row find-form">
                        <div className="col-md-12">
                            <div className="section-text">
                                <p>Accord Manpower is an active and responsible member of eminent bodies who observe a strong code of ethics. We commit to uphold this Code of Ethics &amp; Standards. We support the principles set forth below and acknowledge that compliance with these principles is in the best interests of our candidates, employees, client organizations, and the reputation of the search, employment and staffing services profession.</p>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <ul>
                                        <li>
                                            <p>We observe the highest principles of integrity, professionalism and fair practice in dealing with clients, candidates, employees and all regulatory authorities; and respect the confidentiality of records in accordance with law and good business practices.
                                            </p>
                                        </li>
                                        <li>
                                            <p>We observe the highest principles of integrity, professionalism and fair practice in dealing with clients, candidates, employees and all regulatory authorities; and respect the confidentiality of records in accordance with law and good business practices.
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                We provide leadership in the adherence to both the spirit and letter of all applicable human rights, employment laws and regulations. We will treat all candidates and employees with dignity and respect and will not accept an order from any client that is discriminatory in any way.
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                We take all reasonable steps to provide clients with accurate information on each candidate’s employment qualifications and experience; and will only present those candidates who have given us authorization to represent their application for employment.
                                            </p>
                                        </li>
                                    </ul>
                                </div>

                                <div className="col-md-6">
                                    <ul>
                                        <li>
                                            <p>
                                                We will not recruit, encourage or entice a candidate whom we have previously placed to leave the employ of our client.
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                We either avoid or disclose a potential conflict of interest that might influence or might be perceived to influence personal actions or judgments.
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                We maintain the highest standards of integrity in all forms of advertising, communications and solicitations; and will conduct our business in a manner designed to enhance the operation, image and reputation of the employment, recruitment and staffing services industry.
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                We maintain personal and professional growth in human resources management by engaging in activities that enhance the credibility and value of the profession.
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                We supply candidates and employees with complete and accurate information as provided by the client, regarding terms of employment, job descriptions and workplace conditions.
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                We take prompt action to address employee questions, concerns, or complaints regarding unsafe work conditions, discrimination, or any other matter involving the terms and conditions of their employment.
                                            </p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>

    )
}

export default CodeOfEthics