import React from 'react'

function PageBreadCrum({pageHeading , image }) {
    return (
        <div>
            <section className="page-title title-bg2" style = {{backgroundImage: image ? `url(${image})` : `url(../img/banner/1.jpg)`  }}>
                <div className="d-table">
                    <div className="d-table-cell">
                        <h2>{pageHeading}</h2>
                        <ul>
                            <li>
                                <a href="index.html">Home</a>
                            </li>
                            <li>{pageHeading}</li>
                        </ul>
                    </div>
                </div>
              
            </section>
        </div>
    )
}

export default PageBreadCrum