import React, { useState } from "react";
import Footer from "../../components/common/footer/Footer";
import Header from "../../components/common/header/Header";
import PageBreadCrum from "../../components/common/pageBreadcrum/PageBreadcrum";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { TextArea, TextInput } from "../../components/Form/Form";

function ContactUs({ pageHeading }) {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <div>
      <Header />
      <PageBreadCrum pageHeading="Contact Us" image={`/assets/img/ATS/contact-us.png`} />
      <section>
        <div className>
          <div className="row">
            <div className="col-md-12">
              <div className="google-map contact-card">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14017.949556619116!2d77.2415149!3d28.5551237!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x9e4cf987e4da7b60!2sAccord%20Manpower%20Services!5e0!3m2!1sen!2sin!4v1639992484294!5m2!1sen!2sin"
                  width="100%"
                  height={380}
                  style={{ border: 0 }}
                  allowFullScreen
                  loading="lazy"
                  frameBorder={0}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="contact-card-section pt-10">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <div className="row">
                {/* <div class="col-md-4 col-sm-6">
                          <div class="contact-card">
                              <i class='bx bx-phone-call'></i>
                              <ul>
                                  <li>
                                      <a href="tel:+145664474574">
                                          +1-456-644-7457
                                      </a>
                                  </li>
                                  <li>
                                      <a href="tel:17459674567">
                                          +1-745-967-4567
                                      </a>
                                  </li>
                              </ul>
                          </div>
                      </div> */}
                {/* <div class="col-md-4 col-sm-6">
                          <div class="contact-card">
                              <i class='bx bx-mail-send' ></i>
                              <ul>
                                  <li>
                                      <a href="mailto:info@jovie.com">
                                          info@jovie.com
                                      </a>
                                  </li>
                                  <li>
                                      <a href="mailto:hello@jovie.com">
                                          hello@jovie.com
                                      </a>
                                  </li>
                              </ul>
                          </div>
                      </div> */}
                {/* <div class="col-md-4 col-sm-6 offset-sm-3 offset-md-0">
                          <div class="contact-card">
                              <i class='bx bx-location-plus' ></i>
                              <ul>
                                  <li>
                                      123, Denver, USA
                                  </li>
                                  <li>
                                      Street view 3/B, USA
                                  </li>
                              </ul>
                          </div>
                      </div> */}
                <div className="col-md-6 col-sm-6 offset-sm-3 offset-md-0">
                  <div className="contact-card">
                    <i className="bx bx-location-plus" />
                    <ul>
                      <h5>Head Office:</h5>
                      <li>Unit No. 230-232,</li>
                      <li>Somdutt Chamber II,</li>
                      <li>Bhikaji Cama Place,</li>
                      <li>New Delhi - 110066</li>
                      <li>Phone No: +91-124-4707366</li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-6 col-sm-6 offset-sm-3 offset-md-0">
                  <div className="contact-card">
                    <i className="bx bx-location-plus" />
                    <ul>
                      <h5>Branch Office:</h5>
                      <li>317, 3rd floor</li>
                      <li>Spaze IT park,</li>
                      <li>Sohna road,</li>
                      <li>Sector 49, Gurgaon - 122018</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="contact-form-section pb-100">
        <div className="container">
          <div className="contact-area">
            <h3>Lets' Talk With Us</h3>
            <Formik
              initialValues={{
                name: "",
                email: "",
                phone: "",
                subject: "",
                message: "",
              }}
              validationSchema={Yup.object({
                name: Yup.string().required("Required"),
              })}
              onSubmit={async (values, { setSubmitting, resetForm }) => {
                setSubmitting(true);

                resetForm();
                setSubmitting(false);
              }}
            >
              {(formik) => {
                console.log(formik);
                return (
                  <Form>
                    <div className="row">
                      <div className="col-md-6">
                        <TextInput
                          // label="Name"
                          name="name"
                          type="text"
                          placeholder="Enter Name"
                        />
                      </div>
                      <div className="col-md-6">
                        <TextInput
                          // label="Email"
                          name="email"
                          type="text"
                          placeholder="Enter Email"
                        />
                      </div>
                      <div className="col-md-6">
                        <TextInput
                          // label="Phone"
                          name="phone"
                          type="text"
                          placeholder="Enter Phone"
                        />
                      </div>
                      <div className="col-md-6">
                        <TextInput
                          // label="Subject"
                          name="subject"
                          type="text"
                          placeholder="Enter Subject"
                        />
                      </div>

                      <div className="col-md-12">
                        <TextArea
                          cols={30}
                          rows={7}
                          // label=""
                          name="message"
                          type="text"
                          placeholder="Enter Message"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12 col-md-12 text-center">
                        <button
                          type="submit"
                          className="default-btn contact-btn disabled"
                          style={{ pointerEvents: "all", cursor: "pointer" }}
                        >
                          Send Message
                        </button>
                        <div
                          id="msgSubmit"
                          className="h3 alert-text text-center hidden"
                        />
                        <div className="clearfix" />
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default ContactUs;
