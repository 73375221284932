import React from 'react'
import Footer from '../../components/common/footer/Footer'
import Header from '../../components/common/header/Header'
import PageBreadCrum from '../../components/common/pageBreadcrum/PageBreadcrum'

function OurPeople({pageHeading}) {
    return (
        <div>
            <Header />
            <PageBreadCrum pageHeading="Our People" image={`/assets/img/ATS/About-us/our-people.png`} />

            <div className="section">
                <div className="container">
                    <div className="row find-form">
                        <div className="col-md-12">
                            <div className="section-text">
                                <p>Accord Manpower, the leading provider of talent management solutions, prides itself in building a team of recruitment professionals who have established a global reputation for excellence. Our recruitment specialists have been successfully providing quality placements in multiple markets and industries for over a decade.</p>
                                <p>
                                    As a results driven organisation, our team of recruitment professionals thrive on gathering deep domain knowledge in the clients’ business, in-depth research into the proficiencies and skill-sets that would enable our clients to be successful and achieving the “right fit” between the client and the candidate.
                                </p>
                                <p>
                                    Our forte’ and credibility lie in serving clients with authentic data and we conduct specialised research on each industry vertical to provide our clients with the best resource on ground.
                                </p>
                                <p>
                                    Our team of recruitment professionals, over the years, has built strong and time- tested relationships with the best-in-class talent across verticals so that we can provide the best quality talent solutions on time and on demand.
                                </p>
                                <p>
                                    Besides serving our clients, we pride ourselves in offering a friendly environment, enabling myriad opportunities for employee growth and satisfaction.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <Footer />
        </div>
    )
}

export default OurPeople