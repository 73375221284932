import React from 'react'
import Footer from '../../components/common/footer/Footer'
import Header from '../../components/common/header/Header'
import PageBreadCrum from '../../components/common/pageBreadcrum/PageBreadcrum'
import Client from '../../containers/client/Client'

function AccordLife() {

    return (
        <div>
            <Header />

            <section className="page-title title-bg2" style={{ backgroundImage: `url(/assets/img/accord-life/accord_life_banner_img1.png)` }}>
                <div className="d-table ml-40per">
                    <div className="d-table-cell-accord-life">
                        <h2>Accord Life</h2>
                        <ul >
                            <li>
                                <a href="index.html">Home</a>
                            </li>
                            <li>Accord Life</li>
                        </ul>
                    </div>
                </div>

            </section>
            <section className='pt-50 pb-30'>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="global-rpo-page-title fw-700">
                                <h2><strong>Accord Life</strong></h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="global-rpo-content">
                                <div className="global-rpo-heading">
                                    {
                                        // <h3>Why Choose Accord Manpower Services?</h3>
                                    }
                                </div>
                                <div className="global-rpo-para">
                                    <p>At Accord Manpower, we take delight in cultivating a positive and inclusive work environment that encourages the personal and professional development of our employees. We believe that a happy and motivated team is essential to attaining success, and we are committed to ensuring that our employees always feel valued and supported.</p>

                                    <p>We understand that work is just one part of our employees' lives and are committed to ensuring that our employees have the resources and support they need to thrive both in and out of the workplace.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container">
                    <div className="row">
                        <div className="section-heading">
                            Team Outings
                        </div>
                        <div className="col-md-4">
                            <div className="accord-life-card">
                                <div className="accord-life-card-img">
                                    <img src="/assets/img/accord-life/7.jpg" alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="accord-life-card">
                                <div className="accord-life-card-img">
                                    <img src="/assets/img/accord-life/12.jpg" alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="accord-life-card">
                                <div className="accord-life-card-img">
                                    <img src="/assets/img/accord-life/14.jpg" alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="accord-life-card">
                                <div className="accord-life-card-img">
                                    <img src="/assets/img/accord-life/15.jpg" alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="accord-life-card">
                                <div className="accord-life-card-img">
                                    <img src="/assets/img/accord-life/17.jpg" alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="accord-life-card">
                                <div className="accord-life-card-img">
                                    <img src="/assets/img/accord-life/21.jpg" alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="accord-life-card">
                                <div className="accord-life-card-img">
                                    <img src="/assets/img/accord-life/9.jpg" alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="accord-life-card">
                                <div className="accord-life-card-img">
                                    <img src="/assets/img/accord-life/13.jpg" alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="accord-life-card">
                                <div className="accord-life-card-img">
                                    <img src="/assets/img/accord-life/16.jpg" alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="accord-life-card">
                                <div className="accord-life-card-img">
                                    <img src="/assets/img/accord-life/18.jpg" alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="accord-life-card">
                                <div className="accord-life-card-img">
                                    <img src="/assets/img/accord-life/19.jpg" alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="accord-life-card">
                                <div className="accord-life-card-img">
                                    <img src="/assets/img/accord-life/20.jpg" alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="accord-life-card">
                                <div className="accord-life-card-img">
                                    <img src="/assets/img/accord-life/non-converted/1.jfif" alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="accord-life-card">
                                <div className="accord-life-card-img">
                                    <img src="/assets/img/accord-life/non-converted/2_life.jfif" alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="accord-life-card">
                                <div className="accord-life-card-img">
                                    <img src="/assets/img/accord-life/non-converted/3.jfif" alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="accord-life-card">
                                <div className="accord-life-card-img">
                                    <img src="/assets/img/accord-life/non-converted/4.jfif" alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="accord-life-card">
                                <div className="accord-life-card-img">
                                    <img src="/assets/img/accord-life/non-converted/5.jfif" alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="accord-life-card">
                                <div className="accord-life-card-img">
                                    <img src="/assets/img/accord-life/non-converted/8.jfif" alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="accord-life-card">
                                <div className="accord-life-card-img">
                                    <img src="/assets/img/accord-life/non-converted/10.jfif" alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="accord-life-card">
                                <div className="accord-life-card-img">
                                    <img src="/assets/img/accord-life/non-converted/22.jfif" alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="accord-life-card">
                                <div className="accord-life-card-img">
                                    <img src="/assets/img/accord-life/non-converted/6.jfif" alt="" />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>



            <Client />

            <Footer />
        </div>
    )
}

export default AccordLife