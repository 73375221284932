import React from 'react'
import Footer from '../../components/common/footer/Footer'
import Header from '../../components/common/header/Header'
import PageBreadCrum from '../../components/common/pageBreadcrum/PageBreadcrum'


function ComplianceManagement({ pageHeading }) {
    return (
        <div>
            <Header />
            <PageBreadCrum pageHeading="Compliance Management" image={`/assets/img/ATS/Services/compliance-managment.png`} />
            <div className="section">
                <div className="container">
                    <div className="row find-form">
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-6">
                                    <ul>
                                        <li>
                                            <h4>What is Compliance ?</h4>
                                            <p>Statutory Compliance Management has been one of our forte for more than a decade.</p>
                                            <p><strong>
                                                We offer a two-pronged approach to Compliance Management:
                                            </strong></p>
                                            <ul>
                                                <li>
                                                    <p>Transactional Compliance Management</p>
                                                </li>
                                                <li>
                                                    <p>Compliance Advisory Services</p>
                                                </li>
                                            </ul>
                                            <p>This approach ensures Statutory Compliance both from the organisational perspective as well as from employees’ perspective, safe guarding rights and interests.</p>
                                            <p><strong>What we do?</strong></p>
                                            <ul>
                                                <li>
                                                    <p>Statutory Compliance Audit
                                                    </p>
                                                </li>
                                                <li>
                                                    <p>HR &amp; Statutory Due Diligence</p>
                                                </li>
                                                <li>
                                                    <p>Statutory Benefit Administration</p>
                                                </li>
                                                <li>
                                                    <p>Statutory Advisory Services</p>
                                                </li>
                                                <li>
                                                    <p>To minimize the risk of negligent- hiring lawsuits.</p>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-md-6">
                                    <ul>
                                        <li>
                                            <h4>Indicative list of Labour Regulation we comply with:</h4>
                                            <ul>
                                                <li>
                                                    <p>Factories Act</p>
                                                </li>
                                                <li>
                                                    <p>PF &amp; Misc Provision Act</p>
                                                </li>
                                                <li>
                                                    <p>ESI Act</p>
                                                </li>
                                                <li>
                                                    <p>Minimum Wages Act</p>
                                                </li>
                                                <li>
                                                    <p>Professional Tax Act</p>
                                                </li>
                                                <li>
                                                    <p>Shop and Establishment Act</p>
                                                </li>
                                                <li>
                                                    <p>Labour Welfare Fund</p>
                                                </li>
                                                <li>
                                                    <p>Maternity Benefit Act</p>
                                                </li>
                                                <li>
                                                    <p>Payment of Wages Act
                                                    </p>
                                                </li>
                                                <li>
                                                    <p>Other Labour Regulations as applicable for the establishment</p>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}

export default ComplianceManagement