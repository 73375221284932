import { UPLOAD_CONST } from "../domain/constant";
import axios from "axios";
export async function upload(featuredImage, gallery) {
  const newForm = new FormData();
  if (featuredImage) {
    newForm.append(`file`, featuredImage, featuredImage.name);
  }
  if (gallery) {
    Array.from(gallery).forEach((image, index) => {
      newForm.append(`files[${index}]`, image, image.name);
    });
  }
  const image = await axios.post(UPLOAD_CONST, newForm);
  if (image.data) {
    return image.data;
  }
}

export const convertToFormData = ({ values, featuredImage }) => {
  if (featuredImage) {
    const newForm = new FormData();
    newForm.append(`values`, JSON.stringify(values));
    if (Object.keys(featuredImage)) {
      Object.keys(featuredImage).map((item, index) => {
        if (featuredImage[item]) {
          if (featuredImage[item].length > 0) {
            Array.from(featuredImage[item]).forEach((image, index) => {
              newForm.append(`${item}`, image, image.name);
            });
          } else {
            newForm.append(
              `${item}`,
              featuredImage[item],
              featuredImage[item].name
            );
          }
        }
      });
    }
    return newForm;
  } else {
    const newForm = new FormData();
    newForm.append(`values`, JSON.stringify(values));
    return newForm;
  }
};
