export const GET_JOBPOSTS_STATED = "GET_JOBPOSTS_STATED";
export const GET_JOBPOSTS = "GET_JOBPOSTS";
export const GET_JOBPOSTS_ENDED = "GET_JOBPOSTS_ENDED";
export const ADD_JOBPOST_STATED = "ADD_JOBPOST_STARTED";
export const ADD_JOBPOST = "ADD_JOBPOST";
export const ADD_JOBPOST_ENDED = "ADD_JOBPOST_ENDED";
export const EDIT_JOBPOST_STATED = "EDIT_JOBPOST_STATED";
export const EDIT_JOBPOST = "EDIT_JOBPOST";
export const EDIT_JOBPOST_ENDED = "EDIT_JOBPOST_ENDED";
export const GET_JOBPOST = "GET_JOBPOST";
export const GET_JOBPOST_STATED = "GET_JOBPOST_STATED";
export const GET_JOBPOST_ENDED = "GET_JOBPOST_ENDED";
export const RESET_JOBPOST = "RESET_JOBPOST";
export const ERROR_JOBPOST = "ERROR_JOBPOST";
export const GET_ALL_JOBPOSTS_STATED = "GET_ALL_JOBPOSTS_STATED";
export const GET_ALL_JOBPOSTS = "GET_ALL_JOBPOSTS";
export const GET_ALL_JOBPOSTS_ENDED = "GET_ALL_JOBPOSTS_ENDED";
