import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

export const Footer = (props) => {
  return (
    <div>
      <div>
        <footer className="footer-area pt-100 pb-70">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-sm-6">
                <div className="footer-widget">
                  <div className="footer-logo">
                    <a href="index.html">
                      <img src="/assets/img/footer_logo.png" alt="logo" />
                    </a>
                  </div>
                  <p>
                    Headquartered at Delhi, we provide world class HR services
                    through our company owned offices across India to over 200
                    organizations. The list of our satisfied customers includes
                    leading MNCs and large Indian firms across industries.
                  </p>
                  <div className="footer-social">
                    <a href="https://www.facebook.com/accordmanpower/" target="_blank">
                      <i className="bx bxl-facebook" />
                    </a>
                    <a href="https://twitter.com/AccordManpower1" target="_blank">
                      <i className="bx bxl-twitter" />
                    </a>
                    {
                      //   <a href="#" target="_blank">
                      //   <i className="bx bxl-pinterest-alt" />
                      // </a>
                    }
                    <a href="https://in.linkedin.com/company/accord-manpower-services" target="_blank">
                      <i className="bx bxl-linkedin" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="footer-widget pl-60">
                  <h3>Quick Links</h3>
                  <ul>
                    <li>
                      <Link to="/compliance-management">
                        <i className="bx bx-chevrons-right bx-tada" />
                        Compliance Management
                      </Link>
                    </li>
                    <li>
                      <Link to="/rpo">
                        <i className="bx bx-chevrons-right bx-tada" />
                        Global RPO
                      </Link>
                    </li>
                    <li>
                      <Link to="/recruitment-process">
                        <i className="bx bx-chevrons-right bx-tada" />
                        Recruitment Process
                      </Link>
                    </li>
                    <li>
                      <Link to="/executive-search">
                        {' '}
                        <i className="bx bx-chevrons-right bx-tada" />
                        Executive Search
                      </Link>
                    </li>
                    <li>
                      <Link to="/why-accord">
                        <i className="bx bx-chevrons-right bx-tada" />
                        Why Accord
                      </Link>
                    </li>
                    <li>
                      <Link to="/work-with-us">
                        <i className="bx bx-chevrons-right bx-tada" />
                        Work With Us
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="footer-widget pl-60">
                  <h3>Quick Links</h3>
                  <ul>
                    <li>
                      <Link to="/">
                        <i className="bx bx-chevrons-right bx-tada" />
                        Home
                      </Link>
                    </li>
                    <li>
                      <Link to="/our-unique-approach">
                        <i className="bx bx-chevrons-right bx-tada" />
                        Our Unique Approach
                      </Link>
                    </li>
                    <li>
                      <Link to="/corporate-overview">
                        <i className="bx bx-chevrons-right bx-tada" />
                        Corporate Overview
                      </Link>
                    </li>
                    <li>
                      <Link to="privacy-policy">
                        <i className="bx bx-chevrons-right bx-tada" />
                        Privacy Policy
                      </Link>
                    </li>
                    <li>
                      <Link to="/terms-and-conditions">
                        <i className="bx bx-chevrons-right bx-tada" />
                        Terms &amp; Conditions
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="footer-widget footer-info">
                  <h3>Information</h3>
                  <ul>
                    <li>
                      <span>
                        <i className="bx bxs-phone" />
                        Phone:
                      </span>
                      <a href="tel:+91-124-4707366"> +91-124-4707366 </a>
                    </li>
                    <li>
                      <span>
                        <i className="bx bxs-envelope" />
                        Email:
                      </span>
                      <a href="mailto:info@accordmanpower.com">
                        info@accordmanpower.com
                      </a>
                    </li>
                    {
                      //   <li>
                      //   <span>
                      //     <i className="bx bx-location-plus" />
                      //     Address: New Delhi
                      //   </span>
                      //   140, First Floor, Community Centre, Shopping Complex,
                      //   Zamrudpur, GK-1, New Delhi – 110048
                      // </li>
                    }
                    <li>
                      <div className="footer-new-address">
                        <div className="footer-new-address-box d-flex">
                          <div className="address-icon">
                            <i className="bx bx-location-plus" />
                          </div>
                          <div className="address-city">
                            Address: New Delhi
                          </div>
                        </div>
                        <div className="new-address-content">
                          Unit No. 230-232, Somdutt Chamber II, Bhikaji Cama Place, New Delhi - 110066
                        </div>
                      </div>
                    </li>
                    <li className='pl-0px'>
                      <div className="footer-new-address">
                        <div className="footer-new-address-box d-flex">
                          <div className="address-icon">
                            <i className="bx bx-location-plus" />
                          </div>
                          <div className="address-city">
                            Address:  Gurgaon
                          </div>
                        </div>
                        <div className="new-address-content">
                          317, 3rd floor, Spaze IT park, Sohna road, Sector 49, Gurgaon - 122018
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </footer>
        <div className="copyright-text text-center">
          <p>© Copyright - Accord Manpower Services.</p>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Footer)
