import React from 'react'
import Footer from '../../components/common/footer/Footer'
import Header from '../../components/common/header/Header'
import PageBreadCrum from '../../components/common/pageBreadcrum/PageBreadcrum'
import Client from '../client/Client'


function Rpo({ pageHeading }) {
    return (
        <div>
            <Header />
            <PageBreadCrum pageHeading=" Global Rpo" image={`/assets/img/ATS/Services/Rpo.png`} />
            <section className='pt-50 pb-30'>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="global-rpo-page-title fw-700">
                                <h2><strong>Global RPO</strong></h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="global-rpo-content">
                                <div className="global-rpo-heading">
                                    <h3>Why Choose Accord Manpower Services?</h3>
                                </div>
                                <div className="global-rpo-para">
                                    <p>We are able to provide top talents to our clients anywhere in the world because of our globally focused RPO solutions. We search through our extensive network to identify a candidate that is both objectively and geographically suitable. We at Accord are aware that as the corporate environment changes, so do the requirements for employment.</p>

                                    <p>Our hiring solutions are tailored to your company's requirements. We keep in mind that our working style fits in well with your company's values and management. Our comprehensive RPO solutions will act as an extension of your HR department and aid in resource alignment.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container">
                    <div className="row">
                        <div className="section-heading">
                            Key Benefits
                        </div>
                        <div className="col-md-8">
                            <div className="global-rpo-benefits-bullet-points">
                                <ul>
                                    <li><strong>Dedicated Team</strong>
                                        <p>Our committed team of professionals will work with you to promptly satisfy your business needs.</p>
                                    </li>
                                </ul>

                                <ul>
                                    <li><strong>Cost-effective</strong>
                                        <p>What sets us apart is paving the way for maximum success with cost-effective solutions.</p>
                                    </li>
                                </ul>

                                <ul>
                                    <li><strong>Quality Candidates</strong>
                                        <p>Build an array of qualified, ready-to-use candidate talent pools to meet future talent needs.</p>
                                    </li>
                                </ul>

                                <ul>
                                    <li><strong>Faster Hiring</strong>
                                        <p>Increase the quality of hires and new hire retention while improving performance metrics like time to fill.</p>
                                    </li>
                                </ul>

                                <ul>
                                    <li><strong>Technology Driven RPO</strong>
                                        <p>We have cutting-edge technology at our disposal, which enables us to provide excellent RPO solutions.</p>
                                    </li>
                                </ul>

                                <ul>
                                    <li><strong>Industry Expertise</strong>
                                        <p>Profit from our extensive selection of reliable services, which provides effective answers for a variety of industries.</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="global-rpo-img">
                                <img src="/assets/img/global-rpo-img.png" alt="" />
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <section className="global-rpo-why-choose pt-50 pb-30 p-20">
                <div className="container">
                    <div className="section-heading">
                        Why Choose Accord Manpower As Your RPO Partner?
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4">
                        <div className="global-card">
                            <div className="global-card-icon">
                                <img src="/assets/img/global-rpo/icons/Global-Outreach.png" alt="" /></div>
                            <div className="global-card-title">Global Outreach</div>
                            <div className="global-card-content">
                                Global outreach of our elite recruiters lets you acquire the most talented professionals from the world.
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="global-card">
                            <div className="global-card-icon">
                                <img src="/assets/img/global-rpo/icons/ROI.png" alt="" /></div>
                            <div className="global-card-title">Maximum ROI</div>
                            <div className="global-card-content">
                                At Accord Manpower, we find the top talents in minimum time, thereby giving you the maximum return on your investment.
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="global-card">
                            <div className="global-card-icon">
                                <img src="/assets/img/global-rpo/icons/RPO-Customization.png" alt="" /></div>
                            <div className="global-card-title">RPO Customization</div>
                            <div className="global-card-content">
                                At Accord Manpower, every step of recruitment process outsourcing is customizable to meet your business organization needs.
                            </div>
                        </div>
                    </div>

                </div>
            </section>

            <section className="recruitment-process pt-50 pb-30">
                <div className="container">
                    <div className="row">
                        <div className="section-heading">
                            Recruitment Process
                        </div>
                        <div className="col-md-12">
                            <div className="recruitment-process-content">
                                <div className="recruitment-process-title">
                                    Our Recruitment Process
                                </div>
                                <div className="recruitment-process-para">
                                    <p>The talent acquisition process we use is seamless, error-proof, and systematic, starting with a search for the best candidates who match your needs, followed by screening, database maintenance, authenticity verification, scheduling of interviews, selection, and finally onboarding of the resource. To achieve exceptional results and make quality hires, our recruitment solution focuses on implementing the best talent acquisition strategy.</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

            <Client />

            <Footer />
        </div>
    )
}

export default Rpo