import React from 'react'
import { Link } from 'react-router-dom'

function Category() {
  return (
    <div>
      <section className="categories-section pb-70">
        <div className="container">
          <div className="section-title text-center">
            <h2>CHOOSE A CAREER BASED ON YOUR INTERESTS!</h2>
            <p>
              Don’t lose hope, there’s always Link company looking for you.
              Growth and success go hand-in-hand, we’ll help you with it!s
            </p>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-4 col-sm-6">
              <Link to="/find-job">
                <div className="category-card">
                  <i className="flaticon-accounting" />
                  <h3>Accountancy</h3>
                  {/* <p>You need the best, we have the best.</p> */}
                </div>
              </Link>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">
              <Link to="/find-job">
                <div className="category-card">
                  <i className="flaticon-graduation-cap" />
                  <h3>Education</h3>
                  {/* <p>We provide plenty of opportunities for everyone.</p> */}
                </div>
              </Link>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">
              <Link to="/find-job">
                <div className="category-card">
                  <i className="flaticon-wrench-and-screwdriver-in-cross" />
                  <h3>Automotive Jobs</h3>
                  {/* <p>What we do is match the right person to the right job.</p> */}
                </div>
              </Link>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">
              <Link to="/find-job">
                <div className="category-card">
                  <i className="flaticon-consultation" />
                  <h3>Business</h3>
                  {/* <p>These opportunities are too good to miss!</p> */}
                </div>
              </Link>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">
              <Link to="/find-job">
                <div className="category-card">
                  <i className="flaticon-heart" />
                  <h3>Health Care</h3>
                  {/* <p>Your next role could be with one of the top leading organizations across the world.</p> */}
                </div>
              </Link>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">
              <Link to="/find-job">
                <div className="category-card">
                  <i className="flaticon-computer" />
                  <h3>IT &amp; Agency</h3>
                  {/* <p>We’re committed to making the recruitment process as smooth as possible.</p> */}
                </div>
              </Link>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 offset-md-2 offset-lg-0">
              <Link to="/find-job">
                <div className="category-card">
                  <i className="flaticon-worker" />
                  <h3>Engineering</h3>
                  {/* <p>100 open position</p> */}
                </div>
              </Link>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">
              <Link to="/find-job">
                <div className="category-card">
                  <i className="flaticon-auction" />
                  <h3>Legal</h3>
                  {/* <p>201 open position</p> */}
                </div>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Category
