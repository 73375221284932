import { combineReducers } from "redux";
import alert from "./alert_reducer";
import auth from "./auth_reducer";
import { jobpost_reducer } from "./jobpost_reducer";
import { candidate_reducer } from "./candidate_reducer";

export default combineReducers({
  alert,
  auth,
  jobpost: jobpost_reducer,
  candidate: candidate_reducer,
});
